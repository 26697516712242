import Vue from 'vue'

import vuetify from '@/plugins/vuetify'
import router from '@/router'
import store from '@/store'
import App from '@/components/App/App.vue'

import '@/assets/index.scss'

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
