import Chart from 'chart.js'

export default {
  name: 'ChartComponent',
  data () {
    return {
      chartInstance: null
    }
  },
  methods: {
    chartConstructor (chartId, chartType, chartData, chartOptions) {
      const chartElement = document.getElementById(chartId)

      if (this.chartInstance) {
        this.chartInstance.destroy()
      }

      this.chartInstance = new Chart(chartElement, {
        type: chartType,
        data: chartData,
        options: chartOptions
      })
    }
  },
  props: {
    chartId: String,
    chartType: String,
    chartData: Object,
    chartOptions: Object
  },
  mounted () {
    const { chartId, chartType, chartData, chartOptions } = this
    this.chartConstructor(chartId, chartType, chartData, chartOptions)
  },
  beforeDestroy () {
    if (this.chartInstance) {
      this.chartInstance.destroy()
    }
  }
}
