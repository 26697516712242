import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'Workspace',
  data () {
    return {
      isLoading: {
        planUpdate: false,
        contentplans: false,
        updateWorkspace: false
      },

      firstSignIn: false,

      newPlanName: null,
      newPlanDomain: null,

      popupTrial: false,
      popupPlanId: null
    }
  },
  async mounted () {
    this.loadData()
  },
  watch: {
    async $route () {
      this.loadData()
    },
    async contentplans () {
      this.loadData()
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'workspaces',
      'activeWorkspace',
      'contentplans'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    showTrialEndedDialog () {
      if (!this.activeWorkspace) return false
      return this.activeWorkspace.free_trial && !this.trialCountdown
    },
    trialCountdown () {
      if (!this.activeWorkspace || !this.activeWorkspace.free_trial) return
      const now = new Date()
      let endDate = new Date()

      if (this.activeWorkspace.trial_extended_at) {
        const trialExtensionDays = 7
        const startDate = new Date(this.activeWorkspace.trial_extended_at)
        endDate = new Date(startDate.setDate(startDate.getDate() + trialExtensionDays))
      } else {
        const trialDays = 3
        const startDate = new Date(this.activeWorkspace.created_at)
        endDate = new Date(startDate.setDate(startDate.getDate() + trialDays))
      }

      if (now < endDate) {
        return Math.floor((endDate - now) / (1000 * 60 * 60 * 24))
      }

      return 0
    },
    isTrialExtended () {
      if (!this.activeWorkspace || !this.activeWorkspace.free_trial) return null
      return this.activeWorkspace.trial_extended_at
    }
  },
  methods: {
    ...mapActions([
      'updateWorkspace',
      'loadContentplans'
    ]),
    ...mapMutations([
      'updateActiveWorkspace',
      'setSidebar'
    ]),
    async loadData () {
      this.isLoading.contentplans = true
      if (!this.contentplans) return

      if (this.showTrialEndedDialog) {
        this.popupTrial = true
      }
      this.isLoading.contentplans = false
    },
    async extendTrial () {
      this.isLoading.updateWorkspace = true
      await this.updateWorkspace({
        workspaceId: this.workspaceId,
        action: 'extendTrial'
      })

      await this.loadWorkspaces()

      this.isLoading.updateWorkspace = false
      this.popupTrial = false
    }
  }
}
