export default {
  name: 'KPI',
  computed: {
    valueFormatted () {
      // format the value with thousands separator (using ","), no decimals
      return this.value?.toLocaleString('en-US', { minimumFractionDigits: 0 })
    }
  },
  props: {
    name: String,
    value: Number,
    valueChange: Number,
    tooltip: String
  }
}
