import { mapGetters, mapActions, mapMutations } from 'vuex'

import Sidebar from '@/components/Sidebar/Sidebar.vue'

export default {
  name: 'Crawl',
  components: {
    Sidebar
  },
  data () {
    return {
      timer: null,
      step: null,
      contentplanFirstRun: false,
      crawlStarted: false
    }
  },
  async mounted () {
    this.timer = setInterval(this.loadData, 1000)
  },
  watch: {
    async $route () {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.loadData()
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'crawl'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    percent () {
      if (!this.crawl) return 0
      return Math.round(this.crawl.keywords_crawled * 100 / (this.crawl.keywords_to_crawl)) || 0
    },
    timeLeft () {
      if (!this.crawl) return
      const time = Math.round((this.crawl.keywords_to_crawl - this.crawl.keywords_crawled) * 0.3 / 60)

      return time <= 1 ? 'less than a minute left' : `~${time} minutes left`
    }
  },
  methods: {
    ...mapActions([
      'loadCrawl',
      'queueAllKeywords',
      'startCrawl'
    ]),
    ...mapMutations([
      'clearCrawl'
    ]),
    async loadData () {
      if (!this.contentplanId || !this.workspaceId) {
        clearInterval(this.timer)
        return
      }

      await this.loadCrawl({
        workspaceId: this.workspaceId,
        contentplanId: this.contentplanId
      })

      // If contentplan has no keywords in queue then its running for the first time, so we need to trigger crawl
      if (this.crawl?.keywords_to_crawl === 0) {
        this.contentplanFirstRun = true
      }

      if (this.contentplanFirstRun && !this.crawlStarted && this.crawl?.import_ended && !this.crawl?.crawl_started) {
        this.crawlStarted = true
        await this.queueAllKeywords({
          contentplanId: this.contentplanId
        })
        await this.clearCrawl()
        await this.startCrawl({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId
        })
      }

      await this.stepCounter()

      if (this.crawl?.grouping_ended && this.crawl?.alerts_ended) {
        clearInterval(this.timer)
        this.$router.push({
          name: 'Plan',
          params: {
            contentplanId: this.contentplanId
          }
        })
      }
    },
    async stepCounter () {
      if (!this.crawl) return

      if (this.crawl.import_started && !this.crawl.crawl_started) {
        this.step = 1
      }
      if (this.crawl.import_ended && this.crawl.crawl_started && !this.crawl.grouping_started) {
        this.step = 2
      }
      if (this.crawl.crawl_ended && this.crawl.grouping_started && !this.crawl.grouping_ended) {
        this.step = 3
      }
      if (this.crawl.grouping_ended && !this.crawl.alerts_ended) {
        this.step = 4
      }
    }
  }
}
