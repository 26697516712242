import { mapActions, mapGetters, mapMutations } from 'vuex'
import ChartComponent from '@/components/Chart/Chart.vue'
import chartConfig from './chart.config'

export default {
  name: 'Director',
  data () {
    return {
      sessionsChartKey: '0',
      signupsChartKey: '0',
      signupsRateChartKey: '0',
      calendarOpen: false,
      sessionsData: [],
      signUpsData: [],
      isGAConnected: true,
      sharingInProgress: false,
      sessionChart: {
        data: [],
        loading: true
      },
      signupsChart: {
        data: [],
        loading: true
      },
      error: '',
      datesFilter: [],
      chartOptions: { ...chartConfig },
      rateChartOptions: {
        ...chartConfig,
        scales: {
          ...chartConfig.scales,
          yAxes: chartConfig.scales.yAxes.map(axis => ({
            ...axis,
            ticks: {
              ...axis.ticks,
              callback: function (value) {
                return `${value} %`
              }
            }
          }))
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'activeContentplan',
      'googleAnalyticsIntegration'
    ]),
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan?.name ?? ''
    },
    contentplanSharing () {
      return this.activeContentplan?.sharing ?? ''
    },
    sessionsChartData () {
      return this.processChartData({
        rows: this.sessionChart.data,
        labelKey: 'yearMonth',
        dataKey: 'sessions',
        backgroundColors: ['#c5cae8']
      })
    },
    signupsChartData () {
      return this.processChartData({
        rows: this.signupsChart.data,
        labelKey: 'yearMonth',
        dataKey: 'eventCount',
        backgroundColors: ['#c5cae8', '#9fa8da', '#7986cb', '#e8eaf6', '#5c6bc0']
      })
    },
    signupsRateChartData () {
      const rows = this.extendSignUpDataWithRates()
      return this.processChartData({
        rows,
        labelKey: 'yearMonth',
        dataKey: 'rate',
        backgroundColors: ['#c5cae8', '#9fa8da', '#7986cb', '#e8eaf6', '#5c6bc0']
      })
    },
    chartNoDataError () {
      return this.error === 'No analytics results found'
    },
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    sharingKey () {
      return this.$route.params.sharingKey
    },
    googleAnalyticsEvents () {
      const events = this.googleAnalyticsIntegration?.ga_events

      if (!events) {
        return []
      }

      return events.split(',')
    }
  },
  methods: {
    ...mapActions([
      'updateContentplan',
      'loadContentplan',
      'getGoogleAnalyticsData',
      'getIntegrations'
    ]),
    ...mapMutations(['updateActiveContentplan']),
    formatDate (date) {
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      return `${year}-${month}-${day}`
    },
    setDates (days) {
      const endDate = new Date()
      const startDate = new Date()

      startDate.setDate(startDate.getDate() - days - 3)
      endDate.setDate(endDate.getDate() - 3)
      this.datesFilter = [this.formatDate(startDate), this.formatDate(endDate)]
    },
    getYearlyDateRange () {
      const today = new Date()
      const endDate = new Date(today.getFullYear(), today.getMonth(), 0)
      const startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 11, 1)

      return [
        this.formatDate(startDate),
        this.formatDate(endDate)
      ]
    },
    extendSignUpDataWithRates () {
      const sessionsMap = this.sessionChart.data.reduce((map, item) => {
        map[item.yearMonth] = parseInt(item.sessions)
        return map
      }, {})

      return this.signupsChart.data.map(event => {
        const yearMonth = event.yearMonth
        const totalSessions = sessionsMap[yearMonth]
        const rate = totalSessions ? ((event.eventCount) / totalSessions * 100).toFixed(2) : 0
        console.log('yearmonth', yearMonth, 'totalSessions', totalSessions, 'rate', rate)
        return {
          ...event,
          rate
        }
      })
    },
    async handleShare (share) {
      try {
        this.sharingInProgress = true
        await this.updateContentplan({
          contentplanId: this.contentplanId,
          share
        })

        const contentplan = await this.loadContentplan({
          contentplanId: this.contentplanId
        })

        this.updateActiveContentplan(contentplan)
      } catch (error) {
        console.error(error)
      } finally {
        this.sharingInProgress = false
      }
    },
    async loadSessionsData () {
      try {
        this.error = ''
        this.sessionChart.loading = true
        const dimensions = [{ name: 'yearMonth' }]
        const metrics = [{ name: 'sessions' }]
        const payload = {
          startDate: this.datesFilter[0],
          endDate: this.datesFilter[1],
          contentplanId: this.contentplanId,
          dimensions,
          metrics,
          filters: [{
            filter: {
              fieldName: 'sessionDefaultChannelGrouping',
              stringFilter: {
                value: 'Organic Search'
              }
            }
          }]
        }

        if (this.sharingKey) {
          payload.key = this.sharingKey
        }

        this.sessionChart.data = await this.getGoogleAnalyticsData(payload)
        this.sessionsChartKey = String(Math.random())
      } catch (error) {
        this.error = error?.response?.data?.message ?? error.message
      } finally {
        this.sessionChart.loading = false
      }
    },
    async loadSignupsData () {
      try {
        this.signupsChart.loading = true
        const dimensions = [{ name: 'yearMonth' }, { name: 'eventName' }]
        const metrics = [{ name: 'eventCount' }]
        const payload = {
          startDate: this.datesFilter[0],
          endDate: this.datesFilter[1],
          contentplanId: this.contentplanId,
          dimensions,
          metrics,
          filters: [{
            filter: {
              fieldName: 'sessionDefaultChannelGrouping',
              stringFilter: {
                value: 'Organic Search'
              }
            }
          },
          {
            filter: {
              fieldName: 'eventName',
              inListFilter: {
                values: this.googleAnalyticsEvents
              }
            }
          }]
        }

        if (this.sharingKey) {
          payload.key = this.sharingKey
        }

        this.signupsChart.data = await this.getGoogleAnalyticsData(payload)
        this.signupsChartKey = String(Math.random())
      } catch (error) {
        console.error('Failed fetch GA data', error)
      } finally {
        this.signupsChart.loading = false
      }
    },
    async loadGoogleAnalyticsData () {
      if (this.user || this.sharingKey) {
        await this.loadSessionsData()
        await this.loadSignupsData()
      }
    },
    handleSelectDate () {
      this.calendarOpen = false
      this.loadGoogleAnalyticsData()
    },
    getRandomColor () {
      const r = Math.floor(Math.random() * 256)
      const g = Math.floor(Math.random() * 256)
      const b = Math.floor(Math.random() * 256)
      return `rgb(${r}, ${g}, ${b})`
    },
    processChartData ({ rows = [], labelKey, dataKey, backgroundColors = [] }) {
      const labels = [...new Set(rows.map(row => row[labelKey]))].sort((a, b) => a.localeCompare(b))
      const groupedData = rows.reduce((acc, row) => {
        const key = row.eventName ?? dataKey
        if (!acc[key]) {
          acc[key] = Array(labels.length).fill(0)
        }
        const index = labels.indexOf(row[labelKey])
        acc[key][index] = row[dataKey]
        return acc
      }, {})

      const datasets = Object.keys(groupedData).map((key, index) => {
        const backgroundColor = backgroundColors[index] ? backgroundColors[index] : this.getRandomColor()
        return {
          backgroundColor,
          borderColor: 'white',
          label: key,
          data: groupedData[key],
          borderWidth: 1
        }
      })

      return {
        labels,
        datasets
      }
    },
    isGoogleAnalyticsConnected (integrations = []) {
      const integration = integrations?.find(item => item.type === 'ga-4') ?? null
      if (!integration) {
        return false
      }

      return Boolean(integration?.ga_property)
    }
  },
  async mounted () {
    document.title = 'Director View | ContentGecko'
    this.datesFilter = this.getYearlyDateRange()
    this.loadContentplan({ contentplanId: this.contentplanId })
    const integrations = await this.getIntegrations({ contentplanId: this.contentplanId })
    this.isGAConnected = this.isGoogleAnalyticsConnected(integrations)
    await this.loadGoogleAnalyticsData()
  },
  components: { ChartComponent }
}
