import { mapGetters, mapActions } from 'vuex'
import * as d3 from 'd3'

export default {
  name: 'InternalLinks',
  data () {
    return {
      isLoading: false,
      tableHeaders: [
        { text: 'From URL', value: 'from_url' },
        { text: 'To URL', value: 'to_url' },
        { text: 'Anchor Text', value: 'anchor_text' }
      ],
      incomingHeaders: [
        { text: 'From URL', value: 'from_url' }
      ],
      outgoingHeaders: [
        { text: 'To URL', value: 'to_url' }
      ],
      urlFilter: '',
      uniqueUrls: [],
      selectedUrl: null,
      relatedUrls: [],
      mode: 'graph'
    }
  },
  computed: {
    ...mapGetters([
      'internalLinks'
    ]),
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    incomingLinksToSelectedUrl () {
      if (!this.selectedUrl) return []
      // create copy of internalLinks array
      const internalLinks = [...this.internalLinks]
      // filter internalLinks array to only include links that have the selectedUrl as the to_url
      const incomingLinks = internalLinks.filter(link => link.to_url === this.selectedUrl)
      console.log('Incoming links:', incomingLinks)
      return incomingLinks
    },
    outgoingLinksFromSelectedUrl () {
      if (!this.selectedUrl) return []
      // create copy of internalLinks array
      const internalLinks = [...this.internalLinks]
      // filter internalLinks array to only include links that have the selectedUrl as the from_url
      const outgoingLinks = internalLinks.filter(link => link.from_url === this.selectedUrl)
      console.log('Outgoing links:', outgoingLinks)
      return outgoingLinks
    },
    relatedUrlsData () {
      if (!this.selectedUrl) return []
      return this.incomingLinksToSelectedUrl.concat(this.outgoingLinksFromSelectedUrl)
    }
  },
  methods: {
    ...mapActions([
      'loadInternalLinksByContentplan'
    ]),
    async getInternalLinks () {
      this.isLoading = true

      try {
        await this.loadInternalLinksByContentplan({
          contentplanId: this.contentplanId,
          urlFilter: this.urlFilter
        })
      } catch (error) {
        console.error(error)
      }

      // Extract unique URLs
      this.uniqueUrls = this.calcUniqueUrls(this.internalLinks)

      this.drawInternalLinks(this.internalLinks, this.selectedUrl)
      this.isLoading = false
    },
    drawInternalLinks (internalLinks, selectedUrl) {
      const vueInstance = this

      // If there's an existing SVG, remove it
      d3.select(this.$refs.graph).select('svg').remove()

      // Create nodes for each URL
      const nodes = this.uniqueUrls.map(url => ({ id: url, incomingLinks: this.incomingLinksCount(url, internalLinks) }))
        .sort((a, b) => b.incomingLinks - a.incomingLinks)

      // Create links for each internal link
      const links = internalLinks.map(link => ({ source: link.from_url, target: link.to_url }))

      // Helper function to check if a link exists in both directions
      // const isBidirectional = (source, target) => {
      //   return links.some(link => link.source === target && link.target === source)
      // }

      // Dimensions
      const width = window.innerWidth / 2
      const height = window.innerHeight / 1.5
      const paddingY = height * 0.15
      const paddingX = width * 0.2
      const radius = Math.min(width, height) / 2

      // Create a circular layout
      const angle = (d, i, total) => ((i / total) * 2 * Math.PI) - (Math.PI / 2)
      nodes.forEach((d, i) => {
        d.x = radius * Math.cos(angle(d, i, nodes.length))
        d.y = radius * Math.sin(angle(d, i, nodes.length))
      })

      // Create SVG container
      const svg = d3.select(this.$refs.graph).append('svg')
        .attr('width', width + paddingX * 2) // Add padding to width
        .attr('height', height + paddingY * 2) // Add padding to height
        .append('g')
        .attr('transform', `translate(${(width + paddingX * 2) / 2}, ${(height + paddingY * 2) / 2})`) // Adjust translation

      // Add keydown event listener to window
      window.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          // This function will be called when the ESC key is pressed
          // Reset selected URL
          vueInstance.selectedUrl = null
          vueInstance.relatedUrls = []

          // Select all nodes and links
          const allNodes = svg.selectAll('.node')
          const allLinks = svg.selectAll('.link')
          const allLabels = svg.selectAll('.label')

          // Reset the opacity of all nodes and links
          allNodes
            .style('opacity', 1)
            .style('fill', 'white') // Make nodes empty inside
          allLinks.style('opacity', 1)
          allLabels.style('opacity', 1)

          // Remove highlighted links
          svg.selectAll('.highlighted-link').remove()
        }
      })

      // Define arrow markers for graph links
      svg.append('defs').append('marker')
        .attr('id', 'arrowhead')
        .attr('viewBox', '-0 -5 10 10')
        .attr('refX', 13)
        .attr('refY', 0)
        .attr('orient', 'auto')
        .attr('markerWidth', 13)
        .attr('markerHeight', 13)
        .attr('xoverflow', 'visible')
        .append('svg:path')
        .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
        .attr('fill', '#999')
        .style('stroke', 'none')

      // Draw links
      svg.selectAll('.link')
        .data(links)
        .enter().append('line')
        .attr('class', 'link')
        .attr('x1', d => nodes.find(node => node.id === d.source).x)
        .attr('y1', d => nodes.find(node => node.id === d.source).y)
        .attr('x2', d => nodes.find(node => node.id === d.target).x)
        .attr('y2', d => nodes.find(node => node.id === d.target).y)
        .style('stroke', '#999') // set stroke color
        .style('stroke-width', 1) // set stroke width
        .attr('marker-end', 'url(#arrowhead)') // add arrowhead to the end of the link

      // Create nodes
      const nodeElements = svg.selectAll('.node')
        .data(nodes)
        .enter().append('circle')
        .attr('class', 'node')
        .attr('r', d => Math.sqrt(d.incomingLinks) * 5) // Adjust the multiplier as needed
        .attr('cx', d => d.x)
        .attr('cy', d => d.y)
        .style('fill', 'white') // Make nodes empty inside
        .style('stroke', 'black') // Add a stroke to make the empty nodes visible
        .on('click', function (event, d) {
          // Make the clicked node filled and orange
          d3.select(this).style('fill', 'orange')

          // Select all nodes and links
          const allNodes = svg.selectAll('.node')
          const allLinks = svg.selectAll('.link')
          const allLabels = svg.selectAll('.label')

          // Colour incoming links green and outgoing links blue
          svg.selectAll('.highlighted-link').remove()
          // Hide all standard links
          allLinks.style('opacity', 0)
          const incomingLinks = links.filter(link => link.target === d.id)
          const outgoingLinks = links.filter(link => link.source === d.id)
          incomingLinks.forEach(link => {
            const sourceNode = nodes.find(node => node.id === link.source)
            const targetNode = nodes.find(node => node.id === link.target)
            svg.append('line')
              .attr('class', 'highlighted-link')
              .attr('x1', sourceNode.x)
              .attr('y1', sourceNode.y)
              .attr('x2', targetNode.x)
              .attr('y2', targetNode.y)
              .style('stroke', 'green')
              .style('stroke-width', 1)
              .attr('marker-end', 'url(#arrowhead)')
          })
          outgoingLinks.forEach(link => {
            const sourceNode = nodes.find(node => node.id === link.source)
            const targetNode = nodes.find(node => node.id === link.target)
            svg.append('line')
              .attr('class', 'highlighted-link')
              .attr('x1', sourceNode.x)
              .attr('y1', sourceNode.y)
              .attr('x2', targetNode.x)
              .attr('y2', targetNode.y)
              .style('stroke', 'blue')
              .style('stroke-width', 1)
              .attr('marker-end', 'url(#arrowhead)')
          })
          // Hide all nodes and links that are not connected to the clicked node
          vueInstance.selectedUrl = d.id
          console.log('Selected URL:', vueInstance.selectedUrl)
          vueInstance.relatedUrls = links.filter(link => link.source === vueInstance.selectedUrl || link.target === vueInstance.selectedUrl).map(link => link.source === vueInstance.selectedUrl ? link.target : link.source)
          console.log('Related URLs:', vueInstance.relatedUrls)
          allNodes.style('opacity', node => vueInstance.relatedUrls.includes(node.id) || node.id === d.id ? 1 : 0)
          allLabels.style('opacity', label => vueInstance.relatedUrls.includes(label.id) || label.id === d.id ? 1 : 0)
        })

      // If selectedUrl is not null, simulate a click on the corresponding node
      if (selectedUrl) {
        const selectedNode = nodeElements.filter(d => d.id === selectedUrl).node()
        if (selectedNode) {
          selectedNode.dispatchEvent(new Event('click'))
        }
      }

      // Add URL labels to nodes
      svg.selectAll('.label')
        .data(nodes)
        .enter().append('text')
        .style('font-size', '10px')
        .attr('class', 'label')
        .attr('x', d => d.x + Math.cos(angle(d, nodes.indexOf(d), nodes.length)) * (Math.sqrt(d.incomingLinks) * 6 + Math.abs(Math.cos(angle(d, nodes.indexOf(d), nodes.length))))) // Adjust the offset as needed
        .attr('y', d => d.y + Math.sin(angle(d, nodes.indexOf(d), nodes.length)) * (Math.sqrt(d.incomingLinks) * 6 + Math.abs(Math.sin(angle(d, nodes.indexOf(d), nodes.length))))) // Adjust the offset as needed
        .attr('text-anchor', d => {
          const angleValue = angle(d, nodes.indexOf(d), nodes.length)
          if (angleValue > -Math.PI / 2 && angleValue < Math.PI / 2) {
            return 'start'
          } else if (angleValue < -Math.PI / 2 || angleValue > Math.PI / 2) {
            return 'end'
          } else {
            return 'middle'
          }
        })
        .text(d => this.urlPathOnly(d.id))
    },
    incomingLinksCount (url, internalLinks) {
      return internalLinks.filter(link => link.to_url === url).length
    },
    urlPathOnly (url) {
      const urlObject = new URL(url)
      const path = urlObject.pathname
      const allSlugs = path.split('/').filter(slug => slug !== '')
      const lastSlug = allSlugs[allSlugs.length - 1]
      return lastSlug
    },
    calcUniqueUrls (internalLinks) {
      const uniqueUrls = [...new Set(internalLinks.map(link => link.from_url).concat(internalLinks.map(link => link.to_url)))]

      // sort uniqueUrls by number of incoming links
      const sortedUrls = uniqueUrls.map(url => ({ url, incomingLinks: this.incomingLinksCount(url, internalLinks) }))
        .sort((a, b) => b.incomingLinks - a.incomingLinks)

      return sortedUrls.map(url => url.url)
    }
  },
  async mounted () {
    await this.getInternalLinks()
  }
}
