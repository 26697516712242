import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import Table from '@editorjs/table'

import { mapActions } from 'vuex'

export default {
  name: 'EditorComponent',
  props: {
    input: {
      type: String,
      required: true
    },
    clusterId: {
      type: Number,
      required: true
    },
    contentplanId: {
      type: Number,
      required: true
    }
  },
  mounted () {
    const convertedInput = this.markdownToEditorJs(this.input)

    this.editor = new EditorJS({
      holder: 'editorjs',
      tools: {
        header: Header,
        list: List,
        table: Table
      },
      data: {
        blocks: convertedInput
      },

      onReady: () => {
        // console.log('Editor.js is ready to work!')
      },

      onChange: () => {
        this.editor.save().then(async (outputData) => {
          // console.log(api, event)
          // convert outputData to markdown
          const markdown = this.editorJsToMarkdown(outputData.blocks)
          await this.saveContent(markdown, this.clusterId)
        }).catch((error) => {
          console.log('Saving failed:', error)
        })
      }
    })
  },
  beforeDestroy () {
    if (this.editor) {
      this.editor.destroy()
    }
  },
  methods: {
    ...mapActions([
      'updateArticles'
    ]),
    markdownToEditorJs (markdown) {
      // console log first line of markdown
      const lines = markdown.split('\n')
      const blocks = []
      let i = 0
      let listItems = []
      let listType = null
      let inCodeBlock = false
      let codeContent = ''

      while (i < lines.length) {
        const line = lines[i]
        const trimmedLine = line

        // Handle fenced code blocks
        if (trimmedLine.startsWith('```') || trimmedLine.startsWith('~~~')) {
          console.log('Code block')
          if (!inCodeBlock) {
            inCodeBlock = true
            codeContent = ''
          } else {
            inCodeBlock = false
            blocks.push({
              type: 'code',
              data: {
                code: codeContent.trim()
              }
            })
            codeContent = ''
          }
          i++
          continue
        }

        if (inCodeBlock) {
          codeContent += line + '\n'
          i++
          continue
        }

        // Check for headers
        const headerMatch = trimmedLine.match(/^(#{1,6})\s+(.*)/)
        if (headerMatch) {
          const level = headerMatch[1].length
          const text = headerMatch[2]
          blocks.push({
            type: 'header',
            data: {
              text: this.parseInlineFormat(text),
              level: level
            }
          })
          i++
          continue
        }

        // Check for blockquotes
        if (trimmedLine.startsWith('>')) {
          const text = trimmedLine.replace(/^>\s?/, '')
          blocks.push({
            type: 'quote',
            data: {
              text: this.parseInlineFormat(text)
            }
          })
          i++
          continue
        }

        // Check for list items
        const unorderedListMatch = trimmedLine.match(/^[-*+]\s+(.*)/)
        const orderedListMatch = trimmedLine.match(/^(\d+)\.\s+(.*)/)

        if (unorderedListMatch || orderedListMatch) {
          let itemText
          if (unorderedListMatch) {
            if (listType !== 'unordered') {
              if (listItems.length > 0) {
                blocks.push({
                  type: 'list',
                  data: {
                    style: listType,
                    items: listItems
                  }
                })
                listItems = []
              }
              listType = 'unordered'
            }
            itemText = unorderedListMatch[1]
          } else {
            if (listType !== 'ordered') {
              if (listItems.length > 0) {
                blocks.push({
                  type: 'list',
                  data: {
                    style: listType,
                    items: listItems
                  }
                })
                listItems = []
              }
              listType = 'ordered'
            }
            itemText = orderedListMatch[2]
          }
          listItems.push(this.parseInlineFormat(itemText))
          i++
          continue
        }

        // If we reach here and have list items, end the list
        if (listItems.length > 0) {
          blocks.push({
            type: 'list',
            data: {
              style: listType,
              items: listItems
            }
          })
          listItems = []
          listType = null
        }

        // Check for horizontal rule
        if (trimmedLine.match(/^(-{3,}|_{3,}|\*{3,})$/)) {
          blocks.push({
            type: 'delimiter',
            data: {}
          })
          i++
          continue
        }

        // Check for images
        const imageMatch = trimmedLine.match(/^!$begin:math:display$(.*?)$end:math:display$$begin:math:text$(.*?)$end:math:text$/)
        if (imageMatch) {
          const altText = imageMatch[1]
          const url = imageMatch[2]
          blocks.push({
            type: 'image',
            data: {
              file: {
                url: url
              },
              caption: altText,
              withBorder: false,
              withBackground: false,
              stretched: false
            }
          })
          i++
          continue
        }

        // Check for paragraphs
        if (trimmedLine !== '') {
          const paragraphLines = [trimmedLine]
          i++
          // Add all subsequent lines to the paragraph if they dont include another block type
          while (i < lines.length && lines[i].trim() !== '' && !lines[i].startsWith('#') && !lines[i].startsWith('-') && !lines[i].startsWith('1.') && !lines[i].startsWith('>')) {
            paragraphLines.push(lines[i])
            i++
          }
          const paragraphText = paragraphLines.join(' ')
          blocks.push({
            type: 'paragraph',
            data: {
              text: this.parseInlineFormat(paragraphText)
            }
          })
          continue
        }

        // Empty line
        i++
      }

      // At the end, if there are remaining list items
      if (listItems.length > 0) {
        blocks.push({
          type: 'list',
          data: {
            style: listType,
            items: listItems
          }
        })
      }

      return blocks
    },
    parseInlineFormat (text) {
      // Handle bold **text** or __text__
      text = text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      text = text.replace(/__(.*?)__/g, '<b>$1</b>')
      // Handle italic *text* or _text_
      text = text.replace(/(?<!\*)\*(?!\*)(.*?)\*(?!\*)/g, '<i>$1</i>')
      text = text.replace(/(?<!_)_(?!_)(.*?)_(?!_)/g, '<i>$1</i>')
      // Handle inline code `code`
      text = text.replace(/`(.*?)`/g, '<code>$1</code>')
      // Handle links [text](url)
      text = text = text.replace(/\[(.*?)\]\((.*?)\)/g, '<a href=\'$2\'>$1</a>')
      return text
    },
    editorJsToMarkdown (editorJsData) {
      const lines = []

      for (const block of editorJsData) {
        switch (block.type) {
          case 'header': {
            const level = block.data.level
            const headerLine = '#'.repeat(level) + ' ' + this.reverseInlineFormat(block.data.text)
            lines.push(headerLine)
            lines.push('')
            break
          }

          case 'paragraph': {
            const paragraphText = this.reverseInlineFormat(block.data.text)
            lines.push(paragraphText)
            lines.push('')
            break
          }

          case 'list': {
            const { style, items } = block.data
            if (style === 'unordered') {
              for (const item of items) {
                lines.push('- ' + this.reverseInlineFormat(item))
              }
            } else if (style === 'ordered') {
              for (let i = 0; i < items.length; i++) {
                lines.push(`${i + 1}. ` + this.reverseInlineFormat(items[i]))
              }
            }
            lines.push('')
            break
          }

          case 'code': {
            lines.push('```')
            lines.push(block.data.code)
            lines.push('```')
            lines.push('')
            break
          }

          // You can add more cases here for other block types like 'quote', 'image', etc.
          default:
            console.warn('Unhandled block type:', block.type)
            break
        }
      }

      // Join the lines and remove any extra newlines at the end
      return lines.join('\n').trim()
    },
    reverseInlineFormat (text) {
      // Replace <b>text</b> with **text**
      text = text.replace(/<b>(.*?)<\/b>/g, '**$1**')
      // Replace <i>text</i> with *text*
      text = text.replace(/<i>(.*?)<\/i>/g, '*$1*')
      // Replace <code>code</code> with `code`
      text = text.replace(/<code>(.*?)<\/code>/g, '`$1`')
      // Replace <a href='url'>text</a> with [text](url)
      text = text.replace(/<a href=['"](.*?)['"]>(.*?)<\/a>/g, '[$2]($1)')
      return text
    },
    async saveContent (content) {
      await this.updateArticles({
        contentplanId: this.contentplanId,
        action: 'updateFinalDraft',
        articleIds: [this.clusterId],
        final: content
      })

      console.log('Saved!')
    }
  }
}
