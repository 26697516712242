import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Auth',
  mounted () {
    const action = this.$route.params.action
    const token = this.$route.query.token
    const type = this.$route.query.type || ''
    const workspaceId = this.$route.query.workspaceId || ''
    const accessKey = this.$route.query.accessKey || ''
    const scope = this.$route.query.scope || ''
    const next = this.$route.query.next || window.location.origin + '/auth/in'
    const hutk = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/, '$1') || ''

    if (action === 'in' && token) {
      this.setAuthToken(token)
      this.$router.push('/workspace')
    } else if (action === 'out') {
      this.setAuthToken()
      this.$router.push('/')
    } else {
      window.location.href = `${this.apiUrl}/auth?next=${next}?token=&hutk=${hutk}&scope=${scope}&type=${type}&workspaceId=${workspaceId}&accessKey=${accessKey}`
    }
  },
  computed: {
    ...mapGetters([
      'apiUrl'
    ])
  },
  methods: {
    ...mapMutations([
      'setAuthToken'
    ])
  }
}
