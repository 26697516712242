import { mapGetters, mapActions } from 'vuex'

import EditorJS from '@/components/EditorJS/EditorJS.vue'

export default {
  name: 'ContentEditor',
  data () {
    return {
      isLoading: {
        articles: false
      },

      tableHeaders: [
        { text: 'Title', value: 'final' },
        { text: 'Funnel', value: 'funnel' },
        { text: 'Page Type', value: 'type' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' }
      ],
      selectedArticle: null
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  computed: {
    ...mapGetters([
      'topics',
      'articles',
      'activeContentplan'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan ? this.activeContentplan.name : ''
    },
    groupedArticlesByTopic () {
      if (!this.articles?.length) return {}
      return this.articles.reduce((groups, article) => {
        const topicName = article.topic_name
        if (!groups[topicName]) {
          groups[topicName] = []
        }
        groups[topicName].push(article)
        return groups
      }, {})
    }
  },
  methods: {
    ...mapActions([
      'loadArticlesPublished'
    ]),
    async loadData () {
      this.isLoading.articles = true
      document.title = 'ContentEditor | ContentGecko'

      try {
        await Promise.all([
          this.loadArticlesPublished({
            contentplanId: this.contentplanId
          })
        ])
      } catch (error) {
        console.error('Error loading data:', error)
      }

      this.isLoading.articles = false
    },
    editArticle (article) {
      this.selectedArticle = article // Set the selected article
    },
    updateFinalDraft (newContent) {
      if (this.selectedArticle) {
        this.selectedArticle.final = newContent // Update the final draft content
      }
    },
    closeEditor () {
      this.selectedArticle = null // Close the editor by resetting the selected article
    },
    truncate (text) {
      if (!text) return ''
      const firstLine = text.split('\n')[0]
      // remove # from the beginning
      const firstLinedCleaned = firstLine.replace(/^#+ /, '')
      return firstLinedCleaned.length > 80 ? firstLinedCleaned.substring(0, 80) + '...' : firstLinedCleaned
    },
    capitalizeFirstChar (text) {
      return text.charAt(0).toUpperCase() + text.slice(1)
    }
  },
  async mounted () {
    await this.loadData()
  },
  components: {
    EditorJS
  }
}
