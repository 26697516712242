import { render, staticRenderFns } from "./ChatGPT.pug?vue&type=template&id=5549e9e2&lang=pug&external"
import script from "./ChatGPT.js?vue&type=script&lang=js&external"
export * from "./ChatGPT.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports