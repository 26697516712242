import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LoginManaged',
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  methods: {
    ...mapActions(['getLoginUrl'])
  }
}
