const tooltips = (param) => {
  if (!param) return

  const tooltips = {
    // Metrics
    estimated_search_volume: 'The estimated monthly search volume of the keywords or Clusters. If the keywords source is Related or Uploaded, then the search volume is taken from Google Keyword Planner. If the keywords are imported from Google Search Console, then the search volume is calculated based on your own impression data.',
    potential_clicks: 'The estimated amount of potential additional monthly clicks that you could get with the keyword. If the keyword source is Related or Uploaded, then Potential Clicks equals Search Volume multiplied with the global average CTR for first position results (28.5%). If the keyword source is Google Search Console, then it is calculated based on your impressions, actual CTR, current average position and the expected increase in CTR if the average position were to improve to 1st position. The clicks you already get with this keyword are subtracted.',
    keyword_count: 'The number of keywords in a Cluster',
    google_impressions_last: 'Impressions during the last 30 days (with any URL).',
    google_clicks_last: 'Clicks during the last 30 days (with any URL).',
    google_ctr_last: 'The average CTR of the keywords in this Cluster over the last 30 days (with any URL).',
    google_position_last: 'The average position of the keywords in this Cluster over the last 30 days (with any URL).',
    impressions: 'The number of impressions a URL got during the last 30 days (with any keywords).',
    clicks: 'The number of clicks a URL got during the last 30 days (with any keywords).',
    ctr: 'The average CTR of a URL during the last 30 days (with any keywords).',
    position: 'The average position of a URL during the last 30 days (with any keywords).',
    target_impressions: 'Cluster impressions with only the URL Match during the last 30 days.',
    target_clicks: 'Cluster clicks with only the URL Match during the last 30 days.',
    target_ctr: 'Cluster CTR with only the URL Match during the last 30 days',
    target_position: 'Cluster position with only the URL Match during the last 30 days',
    google_impressions_change: 'The change in cluster impressions compared to the previous 30 days (with any URL).',
    google_clicks_change: 'The change in cluster clicks compared to the previous 30 days (with any URL).',
    google_ctr_change: 'The change in cluster CTR compared to the previous 30 days (with any URL).',
    google_position_change: 'The change in cluster average position compared to the previous 30 days (with any URL).',
    cluster_match_rate: 'Cluster match rate indicates the overlap between URL impressions and matching cluster impressions.',
    // Dimensions
    title: 'A Cluster is group of keywords that are similar enough that you can rank for them with one page.',
    status: 'The status of the cluster in your workflow.',
    funnel: 'The funnel stage of the user journey that the Cluster is targeting.',
    priority: 'The priority of the Cluster to you.',
    alert_cannibalization: 'Use the alerts to help you identify Clusters that can provide quick wins.',
    article_url: 'The Matching URL is the page on your domain that should be answering the keywords in this Cluster',
    url: 'URLs are imported from Google Search Console. They need to have at least 1 impression in the past 30 days to show up in this table.',
    suggested_action: 'The suggested action you could take with the page.',
    published_at: 'The date the page was published.',
    status_code: 'The HTML response status code of the page at the time of our last crawl.',
    alerts: 'Use the alerts to help you identify Clusters that can provide quick wins.'
  }

  return tooltips[param] || ''
}

export default tooltips
