import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Keywords',
  data () {
    return {
      isLoading: {
        keywords: false,
        relatedKeywords: false,
        process: false,
        savingKeywords: false,
        aiKeywords: false
      },

      newUploadKeywords: null,
      relatedKeywordInput: '',
      aiInput: '',

      uploadedKeywords: [],
      relatedKeywords: [],
      aiKeywords: [],

      keywordsReadyToUpload: [],
      newRelatedKeywords: [],
      newAiKeywords: [],

      filterKeyword: '',

      keywordRules: [
        value => !value || value?.split(/[\r\n\t]+/)?.length < 5000 || 'You can add up to 5000 at once',
        value => !value || value?.split(/[\r\n\t]+/)?.length > 1 || 'Enter at least 2 keywords'
      ],

      tabActive: 0,
      tabs: ['Upload a list', 'Search related keywords', 'Ask AI'],

      tableOptions: {
        itemsPerPage: 100,
        page: 1,
        sortBy: ['estimated_search_volume'],
        sortDesc: [true]
      },
      footerProps: {
        'items-per-page-options': [100, 500, 1000]
      },
      tableRelated: {
        headers: [
          { text: 'Keyword', value: 'keyword' },
          { text: 'Search volume', value: 'estimated_search_volume' },
          { text: 'Difficulty', value: 'difficulty' },
          { text: 'Impressions', value: 'google_impressions_last' },
          { text: '', value: 'article_id' }
        ]
      },
      tableAI: {
        headers: [
          { text: 'Keyword', value: 'keyword' },
          { text: 'Search volume', value: 'estimated_search_volume' },
          { text: 'Difficulty', value: 'difficulty' },
          { text: 'Impressions', value: 'google_impressions_last' },
          { text: 'Source', value: 'source' },
          { text: '', value: 'article_id' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'workspaces',
      'activeWorkspace',
      'activeContentplan',
      'keywords'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    keywordsArray () {
      return this.keywords?.keywords || []
    },
    selectedCount () {
      if (!this.keywords?.keywords) return 0

      return (this.keywords?.keywords || []).filter(k => !k.excluded).length
    },
    keywordsHint () {
      if (!this.newUploadKeywords?.length) return 'Enter a list of keywords, one per line'
      if (/,+/u.test(this.newUploadKeywords)) return 'Enter one keyword per line. Don\'t use commas to seperate keywords'
      if (!/^[\p{L}\p{N}\s\n]+$/u.test(this.newUploadKeywords)) return 'Keywords with punctuation or special symbols will be ignored'
      return ''
    },
    countSelectedKeywords () {
      if (this.tabActive === 0) return this.keywordsReadyToUpload?.length || 0
      if (this.tabActive === 1) return this.newRelatedKeywords?.length || 0
      if (this.tabActive === 2) return this.newAiKeywords?.length || 0

      return 0
    },
    relatedKeywordsArray () {
      // return empty array if related keywords is string
      if (!this.relatedKeywords || typeof this.relatedKeywords === 'string') return []
      return this.relatedKeywords
    },
    aiKeywordsArray () {
      // return empty array if related keywords is string
      if (!this.aiKeywords || typeof this.aiKeywords === 'string') return []
      return this.aiKeywords
    }
  },
  methods: {
    ...mapActions([
      'loadContentplanKeywords',
      'uploadKeywords',
      'getRelatedKeywords',
      'processKeywords',
      'getRelatedKeywordsNlp'
    ]),
    clearSelections () {
      this.keywordsReadyToUpload = []
      this.newRelatedKeywords = []
    },
    countRelatedKeywordInput () {
      if (!this.relatedKeywordInput?.length) return 0
      return this.relatedKeywordInput?.split(/[\r\n\t]+/)?.filter(k => k).length || 0
    },
    countNewUploadKeywords () {
      if (!this.newUploadKeywords?.length) return 0
      // count keywords one per line
      return this.newUploadKeywords?.split(/[\r\n\t]+/)?.filter(k => k).length || 0
    },
    async handleAddKeywordsClick () {
      if (this.newRelatedKeywords?.length) {
        await this.saveKeywords(this.newRelatedKeywords, 'related')
        this.newRelatedKeywords = []
      }

      if (this.newAiKeywords?.length) {
        await this.saveKeywords(this.newAiKeywords, 'related')
        this.newAiKeywords = []
      }

      if (this.keywordsReadyToUpload?.length) {
        await this.saveKeywords(this.keywordsReadyToUpload, 'upload')
        this.keywordsReadyToUpload = []
      }
    },
    async processUploadedKeywords () {
      const keywords = this.newUploadKeywords.split(/[\r\n\t]+/)?.filter(k => k?.length)
      this.isLoading.process = true

      try {
        this.uploadedKeywords = await this.processKeywords({
          contentplanId: this.contentplanId,
          keywordsInput: keywords
        })
        this.newUploadKeywords = null
      } catch (e) {
        console.error(e)
      }

      this.isLoading.process = false
    },
    async loadRelatedKeywords () {
      if (!this.relatedKeywordInput?.length) return
      const cleanedKeywords = this.relatedKeywordInput.split(/[\r\n\t]+/)?.filter(k => k?.length)
      if (!cleanedKeywords?.length) return
      // if more than 20 keywords are entered, only take the first 20
      if (cleanedKeywords.length > 20) cleanedKeywords.length = 20
      this.isLoading.relatedKeywords = true

      try {
        this.relatedKeywords = await this.getRelatedKeywords({
          contentplanId: this.contentplanId,
          keywords: cleanedKeywords
        })
      } catch (err) {
        this.errorMessage = err
      }

      this.isLoading.relatedKeywords = false
    },
    async loadRelatedKeywordsNlp () {
      if (!this.aiInput) return
      this.isLoading.aiKeywords = true

      try {
        this.aiKeywords = await this.getRelatedKeywordsNlp({
          prompt: this.aiInput,
          contentplanId: this.contentplanId
        })
      } catch (err) {
        this.errorMessage = err
      }

      this.isLoading.aiKeywords = false
    },
    async saveKeywords (keywordsObject, source) {
      if (!keywordsObject || typeof keywordsObject !== 'object') return
      this.isLoading.savingKeywords = true

      try {
        await this.uploadKeywords({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          keywordsObject,
          source
        })
      } catch (e) {
        console.error(e)
      }

      this.isLoading.savingKeywords = false
      this.$emit('keywords-saved')
    }
  }
}
