import { mapGetters, mapActions } from 'vuex'
import countries from '@/utils/_countries.json'

export default {
  name: 'SearchConsoleIntegration',
  data () {
    return {
      isLoading: {
        integrations: false,
        save: false
      },
      settings: [
        { label: 'Property (required)', id: 'gsc_property', value: '', options: [], disabled: false, required: true },
        { label: 'Country filter', id: 'gsc_country', value: '', options: [...countries], disabled: false, required: false },
        { label: 'URL filter INCLUDE (regexp)', id: 'gsc_url_include', value: '', disabled: false, required: false },
        { label: 'URL filter EXCLUDE (regexp)', id: 'gsc_url_exclude', value: '', disabled: false, required: false }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'integrations',
      'searchConsoleIntegration'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    }
  },
  methods: {
    ...mapActions([
      'getSearchConsoleProperties',
      'postSearchConsoleIntegration',
      'getIntegrations'
    ]),
    async handleConnect () {
      this.isLoading.save = true
      try {
        await this.postSearchConsoleIntegration({
          contentplanId: this.contentplanId,
          property: this.settings[0].value,
          country: this.settings[1].value,
          urlInclude: this.settings[2].value,
          urlExclude: this.settings[3].value
        })
        this.$router.push({
          name: 'ContentplanSettings',
          params: { contentplanId: this.contentplanId }
        })
      } catch (error) {
        console.error(error)
      }
      this.isLoading.save = false
    },
    async loadSearchConsoleProperties () {
      try {
        const properties = await this.getSearchConsoleProperties({
          workspaceId: this.workspaceId
        })
        this.settings[0].options = properties.map(p => p.domain)
      } catch (error) {
        console.error(error)
      }
    },
    fillValues () {
      this.settings.forEach(s => {
        const integration = this.searchConsoleIntegration
        if (integration) {
          s.value = integration[s.id]
        }
      })
    }
  },
  async mounted () {
    this.isLoading.integrations = true
    await this.getIntegrations({ contentplanId: this.contentplanId })
    if (this.searchConsoleIntegration) {
      this.fillValues()
    }
    console.log('this.searchConsoleIntegration', this.searchConsoleIntegration)
    await this.loadSearchConsoleProperties()
    this.isLoading.integrations = false
  }
}
