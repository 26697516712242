import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'WordpressIntegrations',
  data () {
    return {
      websiteUrl: '',
      rules: {
        validUrl: value => {
          const pattern = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/
          return pattern.test(value) || 'Please enter a valid URL.'
        }
      },
      integrations: []
    }
  },
  methods: {
    ...mapActions(['getIntegrations', 'postInteraction', 'loadContentplan']),
    async postIntegration ({ wpUser, wpDomain, password }) {
      try {
        const contentplanId = this.contentplanId
        const payload = {
          contentplanId,
          type: 'wp',
          wpDomain,
          wpUser,
          password
        }

        await this.postInteraction(payload)
      } catch (error) {
        console.error(error)
      }
    }
  },
  computed: {
    ...mapGetters(['activeContentplan', 'activeIntegration']),
    authUrl () {
      const url = window.location.href
      return `${this.websiteUrl}/wp-admin/authorize-application.php?app_name=contentgecko_${this.contentplanId}&success_url=${url}`
    },
    isUrlValid () {
      return this.rules.validUrl(this.websiteUrl) === true
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    activeIntegrationDomain () {
      return this.activeIntegration?.wp_domain
    }
  },
  async mounted () {
    const { query } = this.$route

    await this.loadContentplan({
      contentplanId: this.contentplanId
    })

    if (query.user_login && query.site_url && query.password) {
      await this.postIntegration({ wpUser: query.user_login, wpDomain: query.site_url, password: query.password })
    }

    this.getIntegrations({ contentplanId: this.contentplanId })
  }
}
