import { mapActions, mapGetters } from 'vuex'
import { VueShowdown } from 'vue-showdown'

export default {
  data () {
    return {
      isLoading: {
        generateBrief: false,
        generateResearch: false,
        generateDraft: false,
        generateFinalDraft: false
      },

      userMessage: '',
      systemMessage: 'I am Gecko, AI co-pilot designed to help you produce the best page for this keyword cluster. I have access to all the data you need - keywords, your pages, competitor pages and SERP data. Ask me anything!',
      messages: [],
      socket: null,

      showdownOptions: {
        tables: true,
        emoji: true,
        tasklists: true,
        openLinksInNewWindow: true,
        underline: true,
        simpleLineBreaks: true,
        strikethrough: true,
        highlight: true,
        smoothLivePreview: true,
        simplifiedAutoLink: true,
        parseImgDimensions: true
      }
    }
  },
  mounted () {
    this.connectWebSocket()
  },
  computed: {
    ...mapGetters([
      'websocketUrl'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    articleId () {
      return parseInt(this.$route.params.articleId)
    },
    filteredMessages () {
      return this.messages?.filter(m => m.role !== 'function') || []
    }
  },
  methods: {
    ...mapActions([
      'postCopilot',
      'generateBrief',
      'generateDraft'
    ]),
    async sendMessage (body) {
      try {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
          // console.log('WebSocket connection not established, opening...')
          await this.connectWebSocket(body)
        } else {
          // console.log('WebSocket connection open, sending message...')
          this.socket.send(JSON.stringify(body))
        }
      } catch (err) {
        console.error(err)
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }
    },
    async connectWebSocket (body) {
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        // console.log('WebSocket connection not established, opening...')
        this.socket = new WebSocket(this.websocketUrl)
      }

      this.socket.onopen = () => {
        // console.log('WebSocket connection established', this.socket)
        if (body) {
          this.socket.send(JSON.stringify(body))
        }
      }

      // Handle incoming messages.
      this.socket.onmessage = (event) => {
        // console.log('Message received:', event)
        const response = JSON.parse(event.data)
        if (response?.messages) {
          // console.log('response:', response.messages[0]?.content)
          // append to last assistant message
          if (this.messages[this.messages.length - 1]?.loading) {
            // remove loading message and replace with empty assistant message
            this.messages.pop()
            this.messages.push({ role: 'assistant', content: '' })
          }
          this.messages[this.messages.length - 1].content += response.messages[0]?.content
        }
      }

      this.socket.onerror = (error) => {
        console.error('WebSocket error: ', error.message)
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }
    },
    async copyToClipboard (text) {
      await navigator.clipboard.writeText(text)
    },
    async generateArticleBrief () {
      this.isLoading.generateBrief = true
      // push user message
      this.messages.push({ role: 'user', content: '*Auto-generate article brief*' })
      // create empty assistant message for loading
      this.messages.push({ role: 'assistant', content: '', loading: true })
      this.userMessage = ''

      try {
        await this.sendMessage({
          action: 'generateBrief',
          message: {
            clusterId: this.articleId
          }
        })
      } catch (err) {
        console.error(err)
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }

      this.isLoading.generateBrief = false
    },
    async generateArticleResearch () {
      this.isLoading.generateResearch = true
      // push user message
      this.messages.push({ role: 'user', content: '*Auto-generate article research*' })
      // create empty assistant message for loading
      this.messages.push({ role: 'assistant', content: '', loading: true })
      this.userMessage = ''

      try {
        await this.sendMessage({
          action: 'generateResearch',
          message: {
            clusterId: this.articleId
          }
        })
      } catch (err) {
        console.error(err)
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }

      this.isLoading.generateResearch = false
    },
    async generateArticleDraft () {
      // console.log('generateArticleDraft')
      this.isLoading.generateDraft = true
      // push user message
      this.messages.push({ role: 'user', content: '*Auto-generate article draft*' })
      // create empty assistant message for loading
      this.messages.push({ role: 'assistant', content: '', loading: true })
      this.userMessage = ''

      try {
        await this.sendMessage({
          action: 'generateDraft',
          message: {
            clusterId: this.articleId
          }
        })
      } catch (err) {
        console.error(err)
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }

      this.isLoading.generateDraft = false
    },
    async generateArticleFinalDraft () {
      this.isLoading.generateFinalDraft = true
      // push user message
      this.messages.push({ role: 'user', content: '*Auto-generate final article draft*' })
      // create empty assistant message for loading
      this.messages.push({ role: 'assistant', content: '', loading: true })
      this.userMessage = ''

      try {
        await this.sendMessage({
          action: 'generateFinalDraft',
          message: {
            clusterId: this.articleId
          }
        })
      } catch (err) {
        console.error(err)
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }

      this.isLoading.generateFinalDraft = false
    },
    async sendChatMessage () {
      // console.log('sendChatMessage', this.userMessage)
      // push user message
      this.messages.push({ role: 'user', content: this.userMessage })
      // create empty assistant message for loading
      this.messages.push({ role: 'assistant', content: '', loading: true })
      this.userMessage = ''

      try {
        await this.sendMessage({
          action: 'sendMessage',
          message: {
            contentplanId: this.contentplanId,
            clusterId: this.articleId,
            pageId: this.pageId,
            messages: this.messages.filter(m => m.content !== '')
          }
        })
      } catch (err) {
        console.error(err)
        // rewrite assistant message to error
        this.messages.pop()
        this.messages.push({ role: 'assistant', content: 'Something went wrong here 😬 My bad! Please try again later 🫶' })
      }
    }
  },
  props: {
    pageId: {
      type: Number
    }
  },
  components: {
    VueShowdown
  }
}
