import { mapGetters, mapActions, mapMutations } from 'vuex'

import parseData from '@/utils/parseData'
import tooltips from '@/utils/tooltips'

export default {
  name: 'Page-Keyword Mapper',
  data () {
    return {
      isLoading: {
        articles: false,
        pageKeywords: false,
        articleUpdate: false
      },

      tableExistingPages: {
        headers: [
          { text: 'URL', value: 'article_url' },
          { text: 'Main Keyword', value: 'main_keyword_id' },
          { text: 'URL Impressions', value: 'impressions' }
        ],
        sortBy: 'impressions',
        descending: true
      },

      mainKeywords: []
    }
  },
  computed: {
    ...mapGetters([
      'topics',
      'topic',
      'score',
      'articles',
      'keywords',
      'activeContentplan',
      'pages',
      'articleUrls',
      'pageArticles',
      'pageKeywords'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    existingPages () {
      if (!this.articles) return []

      return this.articles
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  methods: {
    parseData,
    tooltips,
    ...mapActions([
      'loadArticlesAdvanced',
      'loadPageKeywords',
      'updateArticles'
    ]),

    ...mapMutations([
      'articleUpdateMainKeyword'
    ]),

    // RENDER DATA
    async loadData () {
      try {
        this.isLoading.articles = true
        document.title = 'Mindmap | ContentGecko'
        this.articlePage = 1
        await Promise.all([
          this.loadArticlesAdvanced({
            contentplanId: this.contentplanId,
            filter_contentplanId: this.contentplanId,
            orderByColumn: 'impressions',
            orderByDirection: 'desc',
            filter_existing: true
          })
        ])
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading.articles = false
      }
    },
    async getKeywordsByPage (pageId, articleId) {
      this.isLoading.articleUpdate = articleId
      try {
        await this.loadPageKeywords({
          contentplanId: this.contentplanId,
          pageId
        })
      } catch (err) {
        console.log(err)
      }
      this.isLoading.articleUpdate = false
    },
    async updateMainKeyword (articleId, keywordId) {
      // console.log('updateMainKeyword', articleId, keywordId)
      this.isLoading.articleUpdate = articleId
      this.mainKeywords.push({
        articleId,
        keywordId,
        keyword: this.pageKeywords.find(k => k.id === keywordId).keyword
      })

      try {
        this.articleUpdateMainKeyword({
          articleId,
          keywordId
        })
        await this.updateArticles({
          contentplanId: this.contentplanId,
          action: 'updateMainKeyword',
          articleIds: [articleId],
          mainKeywordId: keywordId
        })
      } catch (err) {
        console.log(err)
      }

      this.isLoading.articleUpdate = false
    },
    mainKeywordMapper (article) {
      const keywordId = article?.main_keyword_id
      if (!keywordId) return 'select main keyword'
      let keyword = this.mainKeywords?.find(k => k.keywordId === keywordId)?.keyword

      if (!keyword) {
        keyword = article.title
      }
      if (!keyword) return 'select main keyword'
      return keyword
    }
  },
  async mounted () {
    await this.loadData()
  }
}
