export default {
  scales: {
    yAxes: [{
      type: 'linear',
      display: true,
      position: 'left',
      min: 0,
      ticks: {
        beginAtZero: true
      },
      gridLines: {
        drawOnChartArea: false
      }
    }],
    xAxes: [{
      type: 'time',
      time: {
        unit: 'month',
        isoWeekday: true,
        displayFormats: {
          day: 'MMM DD',
          week: 'MMM DD',
          month: 'MMM YYYY'
        }
      },
      offset: true,
      display: true,
      ticks: {
        beginAtZero: true
      },
      gridLines: {
        drawOnChartArea: false
      }
    }]
  },
  tooltips: {
    mode: 'index',
    intersect: false,
    position: 'nearest'
  },
  legend: {
    display: false
  },
  animation: {
    duration: 1000,
    easing: 'easeInOutQuart'
  },
  responsive: true,
  aspectRatio: 4
}
