import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login/Login.vue'
import LoginManaged from '@/views/LoginManaged/LoginManaged.vue'
import Auth from '@/views/Auth/Auth.vue'
import Home from '@/views/Home/Home.vue'
import Workspace from '@/views/Workspace/Workspace.vue'
import Keywords from '@/views/Keywords/Keywords.vue'
import CreateContentplan from '@/views/CreateContentplan/CreateContentplan.vue'
import Crawl from '@/views/Crawl/Crawl.vue'
import Article from '@/views/Article/Article.vue'
import Plan from '@/views/Plan/Plan.vue'
import Hubs from '@/views/Hubs/Hubs.vue'
import PageKwMapper from '@/views/PageKwMapper/PageKwMapper.vue'
import Pages from '@/views/Pages/Pages.vue'
import Clusterer from '@/views/Clusterer/Clusterer.vue'
import Report from '@/views/Report/Report.vue'
import Sprints from '@/views/Sprints/Sprints.vue'
import Mindmap from '@/views/Mindmap/Mindmap.vue'
import RoiCalculator from '@/views/RoiCalculator/RoiCalculator.vue'
import ContentplanSettings from '@/views/ContentplanSettings/ContentplanSettings.vue'
import WordpressIntegration from '@/views/Integrations/WordpressIntegration/WordpressIntegration.vue'
import GoogleAnalyticsIntegration from '@/views/Integrations/GoogleAnalyticsIntegration/GoogleAnalyticsIntegration.vue'
import SearchConsoleIntegration from '@/views/Integrations/SearchConsoleIntegration/SearchConsoleIntegration.vue'
import Director from '@/views/Director/Director.vue'
import InternalLinks from '@/views/InternalLinks/InternalLinks.vue'
import PaidClusterer from '@/views/PaidClusterer/PaidClusterer.vue'
// import SaaSSemanticClusterer from '@/views/SaaSSemanticClusterer/SaaSSemanticClusterer.vue'
import WorkspaceSettings from '@/views/WorkspaceSettings/WorkspaceSettings.vue'
import StripeLander from '@/views/StripeLander/StripeLander.vue'
import ContentEditor from '@/views/ContentEditor/ContentEditor.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/managed',
    name: 'LoginManaged',
    component: LoginManaged
  },
  {
    path: '/auth/:action',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/workspace',
    name: 'WorkspaceList',
    component: Workspace
  },
  {
    path: '/workspace/:workspaceId',
    name: 'Workspace',
    component: Workspace
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/keywords',
    name: 'Keywords',
    component: Keywords
  },
  {
    path: '/workspace/:workspaceId/contentplan/add',
    name: 'CreateContentplan',
    component: CreateContentplan
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/add',
    name: 'CreateContentplanStep2',
    component: CreateContentplan
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/crawl',
    name: 'Crawl',
    component: Crawl
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/article/:articleId',
    name: 'Article',
    component: Article
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/article/:articleId/:sharingKey',
    name: 'sharedArticle',
    component: Article
  },
  {
    path: '/article/:articleId/:sharingKey',
    name: 'sharedArticleLegacy',
    component: Article
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/plan/:filter?',
    name: 'Plan',
    component: Plan
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/hubs',
    name: 'Hubs',
    component: Hubs
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/page-keyword-mapper',
    name: 'PageKwMapper',
    component: PageKwMapper
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/pages',
    name: 'Pages',
    component: Pages
  },
  {
    path: '/clusterer',
    name: 'Clusterer',
    component: Clusterer
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/report',
    name: 'Report',
    component: Report
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/sprints',
    name: 'Sprints',
    component: Sprints
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/map',
    name: 'Mindmap',
    component: Mindmap
  },
  {
    path: '/roi-calculator',
    name: 'RoiCalculator',
    component: RoiCalculator
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/settings',
    name: 'ContentplanSettings',
    component: ContentplanSettings
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/wordpress-integration',
    name: 'WordpressIntegration',
    component: WordpressIntegration
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/ga-integration',
    name: 'GoogleAnalyticsIntegration',
    component: GoogleAnalyticsIntegration
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/gsc-integration',
    name: 'SearchConsoleIntegration',
    component: SearchConsoleIntegration
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/director',
    name: 'Director',
    component: Director
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/director/:sharingKey',
    name: 'DirectorShared',
    component: Director
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/internal-links',
    name: 'InternalLinks',
    component: InternalLinks
  },
  {
    path: '/workspace/:workspaceId/ultimate-clusterer',
    name: 'PaidClusterer',
    component: PaidClusterer
  },
  // {
  //   path: '/workspace/:workspaceId/semantic-clusterer',
  //   name: 'SaaSSemanticClusterer',
  //   component: SaaSSemanticClusterer
  // },
  {
    path: '/workspace/:workspaceId/settings',
    name: 'WorkspaceSettings',
    component: WorkspaceSettings
  },
  {
    path: '/workspace/:workspaceId/stripe-success',
    name: 'StripeLander',
    component: StripeLander
  },
  {
    path: '/workspace/:workspaceId/contentplan/:contentplanId/content-editor',
    name: 'ContentEditor',
    component: ContentEditor
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
