export default {
  computed: {
    metricsArray () {
      if (this.selectedView === 'overview' && this.topicFilter) {
        return [this.topicFilter]
      }

      return this.selectedKeywords?.length > 0 ? this.selectedKeywords : this.selectedArticles?.length > 0 ? this.selectedArticles : this.selectedPages
    },
    metricsEstimatedSearchVolume () {
      return this.metricsArray.map(x => x.estimated_search_volume).reduce((a, b) => a + b, 0)
    },
    metricsPotentialClicks () {
      return this.metricsArray.map(x => x.potential_clicks).reduce((a, b) => a + b, 0)
    },
    metricsKeywordCount () {
      return this.metricsArray.map(x => x.keyword_count).reduce((a, b) => a + b, 0)
    },
    metricsImpressions () {
      return this.metricsArray.map(x => x.google_impressions_last).reduce((a, b) => a + b, 0)
    },
    metricsImpressionsPrev () {
      return this.metricsArray.map(x => x.google_impressions_prev).reduce((a, b) => a + b, 0)
    },
    metricsClicks () {
      return this.metricsArray.map(x => x.google_clicks_last).reduce((a, b) => a + b, 0)
    },
    metricsClicksPrev () {
      return this.metricsArray.map(x => x.google_clicks_prev).reduce((a, b) => a + b, 0)
    },
    metricsCtr () {
      return this.metricsClicks / this.metricsImpressions * 100
    },
    metricsCtrChange () {
      return this.metricsCtr - (this.metricsClicksPrev / this.metricsImpressionsPrev * 100)
    },
    metricsPosition () {
      let positionArray = this.metricsArray.map(x => x.google_position_last)
      const impressionArray = this.metricsArray.map(x => x.google_impressions_last)
      if (positionArray.length > 1) {
        positionArray = positionArray.map(x => x === 'N/a' ? 100 : x)
        return positionArray.reduce(function (r, a, i) { return r + a * impressionArray[i] }, 0) / impressionArray.reduce((a, b) => a + b, 0)
      }

      return positionArray[0] === 'N/a' ? 'N/a' : positionArray[0]
    },
    metricsPositionPrev () {
      let positionArray = this.metricsArray.map(x => x.google_position_prev)
      const impressionArray = this.metricsArray.map(x => x.google_impressions_prev)
      if (positionArray.length > 1) {
        positionArray = positionArray.map(x => x === 'N/a' ? 100 : x)
        return positionArray.reduce(function (r, a, i) { return r + a * impressionArray[i] }, 0) / impressionArray.reduce((a, b) => a + b, 0)
      }

      return positionArray[0] === 'N/a' ? 'N/a' : positionArray[0]
    },
    metricsUrlImpressions () {
      return this.metricsArray.map(x => x.page_impressions_last).reduce((a, b) => a + b, 0)
    },
    metricsUrlImpressionsPrev () {
      return this.metricsArray.map(x => x.page_impressions_prev).reduce((a, b) => a + b, 0)
    },
    metricsUrlClicks () {
      return this.metricsArray.map(x => x.page_clicks_last).reduce((a, b) => a + b, 0)
    },
    metricsUrlClicksPrev () {
      return this.metricsArray.map(x => x.page_clicks_prev).reduce((a, b) => a + b, 0)
    },
    metricsUrlCtr () {
      return this.metricsUrlClicks / this.metricsUrlImpressions * 100
    },
    metricsUrlCtrPrev () {
      return this.metricsUrlClicksPrev / this.metricsUrlImpressionsPrev * 100
    },
    metricsUrlPosition () {
      let positionArray = this.metricsArray.map(x => x.page_position_last)
      const impressionArray = this.metricsArray.map(x => x.page_impressions_last)
      if (positionArray.length > 1) {
        positionArray = positionArray.map(x => x === 'N/a' ? 100 : x)
        return positionArray.reduce(function (r, a, i) { return r + a * impressionArray[i] }, 0) / impressionArray.reduce((a, b) => a + b, 0)
      }

      return positionArray[0] === 'N/a' ? 'N/a' : positionArray[0]
    },
    metricsUrlPositionPrev () {
      let positionArray = this.metricsArray.map(x => x.page_position_prev)
      const impressionArray = this.metricsArray.map(x => x.page_impressions_prev)
      if (positionArray.length > 1) {
        positionArray = positionArray.map(x => x === 'N/a' ? 100 : x)
        return positionArray.reduce(function (r, a, i) { return r + a * impressionArray[i] }, 0) / impressionArray.reduce((a, b) => a + b, 0)
      }

      return positionArray[0] === 'N/a' ? 'N/a' : positionArray[0]
    },
    metricsUrlImpressionsChange () {
      return this.metricsArray.map(x => x.page_impressions_change).reduce((a, b) => a + b, 0)
    },
    metricsUrlClicksChange () {
      return this.metricsArray.map(x => x.page_clicks_change).reduce((a, b) => a + b, 0)
    },
    metricsUrlCtrChange () {
      return this.metricsUrlClicksChange / this.metricsUrlImpressionsChange * 100
    },
    metricsUrlPositionChange () {
      let positionArray = this.metricsArray.map(x => x.page_position_change)
      const impressionArray = this.metricsArray.map(x => x.page_impressions_change)
      if (positionArray.length > 1) {
        positionArray = positionArray.map(x => x === 'N/a' ? 100 : x)
        return positionArray.reduce(function (r, a, i) { return r + a * impressionArray[i] }, 0) / impressionArray.reduce((a, b) => a + b, 0)
      }

      return positionArray[0] === 'N/a' ? 'N/a' : positionArray[0]
    },
    metricsClusterMatchImpressions () {
      return this.metricsArray.map(x => x.target_impressions).reduce((a, b) => a + b, 0)
    },
    metricsClusterMatchClicks () {
      return this.metricsArray.map(x => x.target_clicks).reduce((a, b) => a + b, 0)
    },
    metricsClusterMatchCtr () {
      return this.metricsClusterMatchClicks / this.metricsClusterMatchImpressions * 100
    },
    metricsClusterMatchPosition () {
      let positionArray = this.metricsArray.map(x => x.target_position)
      const impressionArray = this.metricsArray.map(x => x.target_impressions)
      if (positionArray.length > 1) {
        positionArray = positionArray.map(x => x === 'N/a' ? 100 : x)
        return positionArray.reduce(function (r, a, i) { return r + a * impressionArray[i] }, 0) / impressionArray.reduce((a, b) => a + b, 0)
      }

      return positionArray[0] === 'N/a' ? 'N/a' : positionArray[0]
    }
  }
}
