import { mapGetters, mapActions } from 'vuex'

import parseData from '@/utils/parseData'
import tooltips from '@/utils/tooltips'
import ChartComponent from '@/components/Chart/Chart.vue'

export default {
  name: 'Report',
  data () {
    return {
      isLoading: {
        gscData: false,
        keywordCluster: false,
        pageCluster: false,
        autocompleteArticles: false
      },

      chartData: null,
      chartOptions: {
        scales: {
          yAxes: [{
            type: 'linear',
            display: false,
            position: 'left',
            id: 'y-axis-1',
            min: 0,
            ticks: {
              beginAtZero: true
            }
          },
          {
            type: 'linear',
            display: false,
            position: 'left',
            id: 'y-axis-2',
            min: 0,
            ticks: {
              beginAtZero: true
            }
          },
          {
            type: 'linear',
            display: false,
            position: 'right',
            id: 'y-axis-3',
            min: 0,
            ticks: {
              beginAtZero: true
            }
          },
          {
            type: 'linear',
            display: false,
            position: 'left',
            id: 'y-axis-4',
            min: 0,
            ticks: {
              reverse: true,
              beginAtZero: true
            }
          }],
          xAxes: [{
            type: 'time',
            time: {
              unit: 'week',
              isoWeekday: true,
              displayFormats: {
                day: 'MMM D, YYYY',
                week: 'MMM YYYY',
                month: 'MMM YYYY'
              }
            }
          }]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          position: 'nearest'
        },
        legend: {
          display: false
        },
        animation: {
          duration: 1000,
          easing: 'easeInOutQuart'
        },
        responsive: true,
        aspectRatio: 3
      },

      date: [],
      searchArticle: null,
      selectedTopicIds: null,
      selectableArticles: [],
      selectedArticleIds: [],
      selectedPages: null,
      selectedKeywords: null,
      queryFilter: null,
      queryFilterType: 'includingRegex',
      pageFilter: null,
      pageFilterType: 'includingRegex',
      filterTypes: [
        { text: 'Including regex', value: 'includingRegex', icon: 'mdi-plus' },
        { text: 'Excluding regex', value: 'excludingRegex', icon: 'mdi-minus' }
      ],
      countryOptions: [
        { text: 'All countries', value: 'all' },
        { text: 'Target country only', value: 'target' }
      ],
      country: 'all',
      aggregationOptions: [
        { text: 'Daily', value: 'daily' },
        { text: 'Weekly', value: 'weekly' },
        { text: 'Monthly', value: 'monthly' }
      ],
      aggregation: 'weekly',
      compare: false,
      actions: [
        { text: 'Leave as-is', value: 'leave' },
        { text: 'Merge', value: 'merge' },
        { text: 'Delete', value: 'delete' },
        { text: 'Update', value: 'update' },
        { text: 'Not enough data', value: 'not-enough-data' }
      ],

      tablePages: {
        isLoading: false,
        headers: [
          { text: 'URL', value: 'keys[0]' },
          { text: 'Cluster', value: 'cluster' },
          { text: 'Impressions', value: 'impressions' },
          { text: 'Clicks', value: 'clicks' }
        ],
        sortBy: 'impressions',
        sortDesc: true
      },

      tableKeywords: {
        isLoading: false,
        headers: [
          { text: 'Keyword', value: 'keys[0]' },
          { text: 'Cluster', value: 'cluster' },
          { text: 'Impressions', value: 'impressions' },
          { text: 'Clicks', value: 'clicks' }
        ],
        sortBy: 'impressions',
        sortDesc: true
      },

      selectedMetrics: [
        { text: 'Impressions', value: 'impressions' },
        { text: 'Clicks', value: 'clicks' }
      ],
      metrics: [
        { text: 'Impressions', value: 'impressions' },
        { text: 'Clicks', value: 'clicks' },
        { text: 'CTR', value: 'ctr' },
        { text: 'Rank', value: 'position' }
      ],

      keywordClusterInfo: [],
      pageClusterInfo: [],

      mode: 'general',
      modes: [
        { text: 'General overview', value: 'general' },
        { text: 'Published changes', value: 'updated' }
      ],
      modeError: false,

      deadlineRange: []
    }
  },
  computed: {
    ...mapGetters([
      'topics',
      'articles',
      'activeContentplan',
      'gsc',
      'pages',
      'keywords'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    gscImpressions () {
      if (!this.gsc.byDate || this.gscErrorResponse) return
      return this.gsc.byDate.map(x => x.impressions).reduce((a, b) => a + b, 0)
    },
    gscClicks () {
      if (!this.gsc.byDate || this.gscErrorResponse) return
      return this.gsc.byDate.map(x => x.clicks).reduce((a, b) => a + b, 0)
    },
    gscCtr () {
      if (!this.gsc.byDate || this.gscErrorResponse) return
      return parseData(this.gscClicks / this.gscImpressions * 100, 1) + '%'
    },
    gscPosition () {
      if (!this.gsc.byDate || this.gscErrorResponse) return
      let positionArray = this.gsc.byDate.map(x => x.position)
      const impressionArray = this.gsc.byDate.map(x => x.impressions)
      if (positionArray.length > 1) {
        positionArray = positionArray.map(x => x === 'N/a' ? 100 : x)
        return parseData(positionArray.reduce(function (r, a, i) { return r + a * impressionArray[i] }, 0) / impressionArray.reduce((a, b) => a + b, 0), 1)
      }

      return positionArray[0] === 'N/a' ? 'N/a' : parseData(positionArray[0], 1)
    },
    sortedArticles () {
      if (!this.articles) return
      let tempArticles = [...this.articles]

      if (this.selectedTopicIds) {
        tempArticles = tempArticles.filter(x => x.topic_id === this.selectedTopicIds)
      }

      return tempArticles.sort((a, b) => {
        return b.google_impressions_last - a.google_impressions_last
      })
    },
    sortedTopics () {
      if (!this.topics) return
      return [...this.topics].sort((a, b) => b.estimated_search_volume - a.estimated_search_volume)
    },
    gscErrorResponse () {
      if (!this.gsc) return false
      if (typeof this.gsc.byDate === 'string') {
        console.log(this.gsc.byDate)
        return this.gsc.byDate
      }
      return false
    },
    gscQueriesSorted () {
      if (!this.gsc.byQuery) return
      return [...this.gsc.byQuery].sort((a, b) => b.impressions - a.impressions)
    },
    gscPagesSorted () {
      if (!this.gsc.byPage) return
      return [...this.gsc.byPage].sort((a, b) => b.impressions - a.impressions)
    },
    deadlineRangeFormatted () {
      if (!this.deadlineRange.length) return
      const [startDate, endDate] = this.transformDates(this.deadlineRange)

      // return date range as string in format 'July 2023 (- September 2023)'
      const startMonth = startDate.toLocaleString('default', { month: 'short' })
      const startYear = startDate.getFullYear()
      const startString = `${startMonth} ${startYear}`

      let string = startString

      if (endDate) {
        const endMonth = endDate.toLocaleString('default', { month: 'short' })
        const endYear = endDate.getFullYear()
        const endString = `${endMonth} ${endYear}`

        if (startString !== endString) string += ` - ${endString}`
      }

      return string
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  methods: {
    parseData,
    tooltips,
    ...mapActions([
      'loadTopics',
      'loadArticles',
      'getKeyword',
      'getPage',
      'getGSCDataByDate',
      'getGSCDataByQuery',
      'getGSCDataByPage'
    ]),

    // RENDER DATA

    async loadData () {
      this.isLoading.gscData = true
      document.title = 'Report | ContentGecko'
      this.articlePage = 1

      // set this.date to a range of the last 30 days in the format YYYY-MM-DD
      this.setDates(360)

      // set deadline range to the last month in format [YYYY-MM]
      const date = new Date()
      const lastMonth = `${date.getFullYear()}-${('0' + (date.getMonth())).slice(-2)}`
      this.deadlineRange = [lastMonth]

      try {
        this.loadTopics({
          contentplanId: this.contentplanId
        })
        await Promise.all([
          this.getGSCDataByDate({
            contentplanId: this.contentplanId,
            startDate: this.date[0],
            endDate: this.date[1],
            aggregation: this.aggregation,
            country: this.country
          }),
          this.getGSCDataByQuery({
            contentplanId: this.contentplanId,
            startDate: this.date[0],
            endDate: this.date[1],
            country: this.country,
            dimensions: 'QUERY'
          }),
          this.getGSCDataByPage({
            contentplanId: this.contentplanId,
            startDate: this.date[0],
            endDate: this.date[1],
            country: this.country,
            dimensions: 'PAGE'
          })
        ])
      } catch (error) {
        console.log(error)
      } finally {
        if (!this.gscErrorResponse) {
          this.calcChartData()
        } else {
          this.isLoading.gscData = false
        }
      }
    },
    async loadAutocompleteArticles () {
      clearTimeout(this.timeout) // Clear previous timeout
      this.timeout = setTimeout(async () => {
        if (!this.searchArticle) return

        this.isLoading.autocompleteArticles = true

        try {
          await this.loadArticles({
            workspaceId: this.workspaceId,
            contentplanId: this.contentplanId,
            filterTitle: this.searchArticle,
            filterHasStatus: this.mode === 'updated'
          })
        } catch (error) {
          console.log(error)
        }

        this.isLoading.autocompleteArticles = false
      }, 500)
    },
    async refreshGSCData () {
      this.isLoading.gscData = true

      // if selectedPages is object select keys value, else return selectedPages
      const pageFilter = this.selectedPages ? this.selectedPages?.keys[0] : this.pageFilter
      const keywordFilter = this.selectedKeywords ? this.selectedKeywords?.keys[0] : this.queryFilter

      if (this.mode === 'updated' && !this.selectedArticleIds?.length) {
        this.modeError = 'No clusters selected'
        this.isLoading.gscData = false
        return
      }

      try {
        await Promise.all([
          this.getGSCDataByDate({
            contentplanId: this.contentplanId,
            startDate: this.date[0],
            endDate: this.date[1],
            aggregation: this.aggregation,
            topicIds: this.selectedTopicIds,
            articleIds: this.selectedArticleIds,
            pages: pageFilter,
            pageFilterType: this.pageFilterType,
            keywords: keywordFilter,
            queryFilterType: this.queryFilterType,
            country: this.country
          }),
          this.getGSCDataByQuery({
            contentplanId: this.contentplanId,
            startDate: this.date[0],
            endDate: this.date[1],
            country: this.country,
            topicIds: this.selectedTopicIds,
            articleIds: this.selectedArticleIds,
            pages: pageFilter,
            pageFilterType: this.pageFilterType,
            keywords: keywordFilter,
            queryFilterType: this.queryFilterType,
            dimensions: 'QUERY'
          }),
          this.getGSCDataByPage({
            contentplanId: this.contentplanId,
            startDate: this.date[0],
            endDate: this.date[1],
            country: this.country,
            topicIds: this.selectedTopicIds,
            articleIds: this.selectedArticleIds,
            pages: pageFilter,
            pageFilterType: this.pageFilterType,
            keywords: keywordFilter,
            queryFilterType: this.queryFilterType,
            dimensions: 'PAGE'
          })
        ])
      } catch (error) {
        console.log(error)
      } finally {
        if (!this.gscErrorResponse) {
          this.calcChartData()
        } else {
          this.isLoading.gscData = false
        }
      }
    },
    async calcChartData () {
      if (!this.gsc.byDate) return

      const dateArray = this.gsc.byDate?.map(x => x.keys[0])

      this.chartData = {
        labels: dateArray,
        datasets: []
      }

      if (this.selectedMetrics.some(x => x.value === 'impressions')) {
        this.chartData.datasets.push({
          label: 'Impressions',
          backgroundColor: 'white',
          borderColor: '#5e35b1',
          fill: false,
          data: this.gsc.byDate?.map(x => x.impressions),
          yAxisID: 'y-axis-1'
        })
      }

      if (this.selectedMetrics.some(x => x.value === 'clicks')) {
        this.chartData.datasets.push({
          label: 'Clicks',
          backgroundColor: 'white',
          borderColor: '#4285f4',
          fill: false,
          data: this.gsc.byDate?.map(x => x.clicks),
          yAxisID: 'y-axis-2'
        })
      }

      if (this.selectedMetrics.some(x => x.value === 'ctr')) {
        this.chartData.datasets.push({
          label: 'CTR',
          backgroundColor: 'white',
          borderColor: '#21897b',
          fill: false,
          data: this.gsc.byDate?.map(x => parseData(x.ctr * 100, 1)),
          yAxisID: 'y-axis-3'
        })
      }

      if (this.selectedMetrics.some(x => x.value === 'position')) {
        this.chartData.datasets.push({
          label: 'Rank',
          backgroundColor: 'white',
          borderColor: '#e8710b',
          fill: false,
          data: this.gsc.byDate?.map(x => parseData(x.position, 1)),
          yAxisID: 'y-axis-4'
        })
      }
      this.isLoading.gscData = false
    },
    changeTopicFilter () {
      this.selectedKeywords = null
      this.selectedArticleIds = []
      this.queryFilter = null
      this.queryFilterType = 'includingRegex'
      this.refreshGSCData()
    },
    changeClusterFilter () {
      this.selectedKeywords = null
      this.queryFilter = null
      this.queryFilterType = 'includingRegex'
      this.refreshGSCData()
    },
    metricIsActive (metric) {
      return this.selectedMetrics.some(x => x.value.includes(metric))
    },
    async metricChange (metric) {
      this.isLoading.gscData = true
      if (this.metricIsActive(metric)) {
        this.selectedMetrics = this.selectedMetrics.filter(x => !x.value.includes(metric))
      } else {
        this.selectedMetrics.push(this.metrics.find(x => x.value === metric))
      }

      this.updateTableHeaders()
      // wait before calculating chart data
      setTimeout(async () => {
        await this.calcChartData()
      }, 100)
    },
    updateTableHeaders () {
      this.tableKeywords.headers = [
        this.tableKeywords.headers[0],
        this.tableKeywords.headers[1],
        ...this.selectedMetrics
      ]

      this.tablePages.headers = [
        this.tablePages.headers[0],
        this.tablePages.headers[1],
        ...this.selectedMetrics
      ]
    },
    async populateKeywordClusterInfo (items) {
      if (this.isLoading.keywordCluster || !items) return
      this.isLoading.keywordCluster = true

      items.forEach(async kw => {
        if (this.keywordClusterInfo.some(x => x.keyword === kw.keys[0])) return

        try {
          const result = await this.getKeyword({
            keyword: kw.keys[0],
            contentplanId: this.contentplanId
          })
          this.keywordClusterInfo.push(result[0])
        } catch (error) {
          console.log(error)
        }
      })

      this.isLoading.keywordCluster = false
    },
    keywordCluster (item) {
      const emptyResult = [{
        article_id: null,
        title: null,
        excluded: null
      }]

      const cluster = this.keywordClusterInfo.find(x => x.keyword === item.keys[0])
      if (!cluster) return emptyResult

      return {
        article_id: cluster.article_id,
        title: cluster.title,
        excluded: cluster.excluded
      }
    },
    async populatePageClusterInfo (items) {
      if (this.isLoading.pageCluster || !items) return
      this.isLoading.pageCluster = true

      try {
        items.forEach(async page => {
          if (this.pageClusterInfo.some(x => x.url === page.keys[0])) return

          try {
            const result = await this.getPage({
              page: page.keys[0],
              contentplanId: this.contentplanId
            })
            this.pageClusterInfo.push(result[0])
          } catch (error) {
            console.log(error)
          }
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading.pageCluster = false
      }
    },
    pageCluster (item) {
      const emptyResult = [{
        article_id: null,
        title: null,
        excluded: null
      }]

      const cluster = this.pageClusterInfo.find(x => x.url === item.keys[0])
      if (!cluster) return emptyResult

      return {
        article_id: cluster.article_id,
        title: cluster.title,
        excluded: cluster.excluded
      }
    },
    formatDate (date) {
      const year = date.getFullYear()
      // JavaScript months are 0-based, so +1 is added to get the correct month number
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      return `${year}-${month}-${day}`
    },
    setDates (days) {
      const endDate = new Date()
      const startDate = new Date()

      // report date
      startDate.setDate(startDate.getDate() - days - 3)
      endDate.setDate(endDate.getDate() - 3)
      this.date = [this.formatDate(startDate), this.formatDate(endDate)]
    },
    transformDates (dates) {
      // sort date array
      const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b))

      const startDate = new Date(sortedDates[0])
      startDate.setDate(1) // Set to the first day of the month

      const endDate = new Date(sortedDates[1] || sortedDates[0])
      endDate.setMonth(endDate.getMonth() + 1) // Move to the next month
      endDate.setDate(0) // Set to the last day of the previous month

      return [startDate, endDate]
    },
    async changeMode (mode) {
      this.mode = mode
      this.selectedArticleIds = []
      this.modeError = false
      this.isLoading.gscData = true

      if (this.mode === 'updated') {
        this.chartOptions.aspectRatio = 2
        await this.getUpdatedClusters()
      }

      if (this.mode === 'general') {
        this.chartOptions.aspectRatio = 3
      }

      this.refreshGSCData()
    },
    async getUpdatedClusters () {
      try {
        await this.loadArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          filterHasStatus: true
        })

        const updatedClusters = this.articles.filter(x => x.status === 'published')
        // get start and end date of deadline range
        const [deadlineRangeStartDate, deadlineRangeEndDate] = this.transformDates(this.deadlineRange)

        // Filter based on deadline range
        if (this.deadlineRange.length > 0) {
          this.selectableArticles = updatedClusters.filter(x => {
            // check if deadline is in range
            const deadline = new Date(x.deadline)
            return deadline >= deadlineRangeStartDate && deadline <= deadlineRangeEndDate
          })
        }

        this.selectedArticleIds = this.selectableArticles.map(x => x.article_id)
      } catch (error) {
        console.log(error)
      }
    },
    isArticleSelected (articleId) {
      return this.selectedArticleIds.includes(articleId)
    },
    async selectArticle (articleId) {
      this.modeError = false
      if (this.isArticleSelected(articleId)) {
        this.selectedArticleIds = this.selectedArticleIds.filter(x => x !== articleId)
      } else {
        this.selectedArticleIds.push(articleId)
      }

      this.refreshGSCData()
    },
    async selectOneArticle (articleId) {
      this.modeError = false
      this.selectedArticleIds = [articleId]
      this.refreshGSCData()
    },
    changeAggregation (aggregation) {
      this.aggregation = aggregation
      this.chartOptions.scales.xAxes[0].time.unit = aggregation === 'daily' ? 'day' : aggregation === 'weekly' ? 'week' : 'month'
      console.log('aggregation', this.aggregation, this.chartOptions.scales.xAxes[0].time.unit)
      this.refreshGSCData()
    }
  },
  components: { ChartComponent },
  async mounted () {
    await this.loadData()
  }
}
