import { mapGetters, mapActions } from 'vuex'
import countries from '@/utils/_countries.json'
import languages from '@/utils/_languages.json'

export default {
  name: 'CreateContentplan',
  data () {
    return {
      isLoading: {
        domains: false,
        keywords: false,
        saving: false,
        connectGsc: false
      },
      name: null,
      country: null,
      language: null,
      gscProperty: null,

      step: 1,
      loading: false,

      noToken: false,
      noAccess: false,
      noDomains: false,
      domains: null,
      countries,
      languages
    }
  },
  async mounted () {
    await this.loadData()
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    isFormValid () {
      return this.name && this.country && this.language
    },
    gscConnected () {
      if (this.isLoading.domains) return false
      if (this.noToken || this.noAccess) return false
      return this.domains?.length > 0
    }
  },
  methods: {
    ...mapActions([
      'createContentplan',
      'runUpdateStatemachine',
      'postSearchConsoleIntegration',
      'getSearchConsoleProperties',
      'updateWorkspace'
    ]),
    async loadData () {
      this.isLoading.domains = true

      try {
        this.domains = await this.getSearchConsoleProperties({
          workspaceId: this.workspaceId
        })
        if (!this.domains.length) this.noDomains = true
      } catch (err) {
        if (err?.response?.data?.message === 'No refresh token') {
          console.log('No refresh token')
          this.noToken = true
        } else if (err?.response?.data?.message === 'Bad Request' || err?.response?.data?.message?.error?.code === 403) {
          console.log('No access')
          this.noAccess = true
        } else {
          console.log('Error message', err?.response?.data?.message)
        }
      }

      if (this.contentplanId) this.step = 2
      this.isLoading.domains = false
    },
    async saveContentplan () {
      if (!this.name || !this.country || !this.language) return

      this.isLoading.saving = true

      const contentplan = await this.createContentplan({
        workspaceId: this.workspaceId,
        name: this.name,
        country: this.country,
        language: this.language
      })

      // add contentplanId to route
      this.$router.push({
        name: 'CreateContentplanStep2',
        params: {
          workspaceId: this.workspaceId,
          contentplanId: contentplan.contentplanId
        }
      })

      this.isLoading.saving = false
      this.noToken = true
      this.step = 2
    },
    async saveIntegration () {
      if (!this.gscProperty) return
      this.isLoading.saving = true
      const accessUser = this.domains.find(d => d.domain === this.gscProperty)?.userId
      console.log('Access user:', accessUser)

      try {
        await this.postSearchConsoleIntegration({
          contentplanId: this.contentplanId,
          property: this.gscProperty,
          accessUser: accessUser || null
        })
      } catch (err) {
        console.error(err)
      }

      this.isLoading.saving = false
      this.step = 3
    },
    async handleConnectGscClick () {
      this.isLoading.connectGsc = true
      const nextUrl = window.location.href
      const scope = 'search-console'

      let accessKey
      try {
        const result = await this.updateWorkspace({
          workspaceId: this.workspaceId,
          action: 'generateAccessKey'
        })
        accessKey = result.accessKey
        console.log('Access key:', accessKey)
      } catch (err) {
        console.error(err)
      }

      const params = `next=${nextUrl}&contentplanId=${this.contentplanId}&scope=${scope}&workspaceId=${this.workspaceId}&accessKey=${accessKey}`
      const authUrl = window.location.origin + '/auth/in?' + params
      console.log('Opening URL:', authUrl) // Debugging line to check the authUrl
      window.location.href = authUrl
    }
  }
}
