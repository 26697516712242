import { mapGetters, mapActions } from 'vuex'

import parseData from '@/utils/parseData'
import ChartComponent from '@/components/Chart/Chart.vue'
import Dendrogram from '@/components/Dendrogram/Dendrogram.vue'
import ChatGPT from '@/components/ChatGPT/ChatGPT.vue'
import { VueShowdown } from 'vue-showdown'

export default {
  name: 'Article',
  data () {
    return {
      isLoading: {
        article: false,
        setPriority: false,
        setFunnel: false,
        setStatus: false,
        setDeadline: false,
        setShowInMap: false,
        score: false,
        targetUrl: false,
        lockingArticle: false,
        selectableTargetUrls: false,
        updatePageAction: false,
        updatePageMergeWith: false,
        generating: false,
        autoFill: false,
        chartDataPotential: false,
        chartDataImpressions: false,
        deleteArticles: false,
        mergeCluster: false,
        unexcludeKeywords: false,
        generateBrief: false,
        generateDraft: false,
        pageData: false
      },
      savingBrief: false,
      savingResearch: false,
      savingDraft: false,
      savingFinalDraft: false,
      saveTimeoutBrief: null,
      saveTimeoutResearch: null,
      saveTimeoutDraft: null,
      saveTimeoutFinalDraft: null,
      outlineBuilder: false,
      searchKeywords: '',
      notesExpanded: false,
      dialogDelete: false,
      dialogMerge: false,
      dialogDeleteCompeting: false,
      dialogModifyArticle: false,
      dialogKey: 0,
      cmMetricsOnly: false,

      title: null,
      description: '',
      research: '',
      draft: '',
      finalDraft: '',
      mergeWith: null,
      pageData: {},
      notesPreviewMode: false,
      showdownOptions: {
        tables: true,
        emoji: true,
        tasklists: true,
        openLinksInNewWindow: true,
        underline: true,
        simpleLineBreaks: true,
        strikethrough: true,
        highlight: true,
        smoothLivePreview: true,
        simplifiedAutoLink: true,
        parseImgDimensions: true
      },

      selectedCompetitors: [],
      selectableTargetUrls: [],
      competingClusters: [],

      statuses: [
        { text: 'No status', value: 'no status' },
        { text: 'Draft', value: 'draft' },
        { text: 'Brief sent', value: 'brief sent' },
        { text: 'In review', value: 'in review' },
        { text: 'Published', value: 'published' }
      ],
      priorities: [
        { text: '👍', value: 'high' },
        { text: '-', value: 'not set' },
        { text: '👎', value: 'low' }
      ],
      funnel: [
        { text: 'Top', value: 'top' },
        { text: 'Middle', value: 'middle' },
        { text: 'Bottom', value: 'bottom' }
      ],
      actions: [
        { text: 'Leave as-is', value: 'leave' },
        { text: 'Merge', value: 'merge' },
        { text: 'Delete', value: 'delete' },
        { text: 'Update', value: 'update' },
        { text: 'Not enough data', value: 'not-enough-data' }
      ],
      types: [
        { text: 'Glossary', value: 'glossary' },
        { text: 'Article', value: 'article' },
        { text: 'Asset', value: 'asset' },
        { text: 'Listicle', value: 'listicle' },
        { text: 'Buyers guide', value: 'buyers-guide' },
        { text: 'Category', value: 'category' },
        { text: 'Product', value: 'product' },
        { text: 'Home', value: 'home' }
      ],
      intents: [
        { text: 'Informational', value: 'informational' },
        { text: 'Transactional', value: 'transactional' },
        { text: 'Navigational', value: 'navigational' }
      ],

      tableArticleKeywords: {
        isLoading: false,
        headers: [
          { text: 'Keywords', value: 'keyword' },
          { text: 'Cluster Rank', value: 'google_position_last' },
          { text: 'Cluster Clicks', value: 'google_clicks_last' },
          { text: 'Cluster Impressions', value: 'google_impressions_last' },
          { text: 'Search Volume', value: 'estimated_search_volume' }
        ],
        sortBy: 'google_impressions_last',
        descending: true
      },

      tablePageKeywords: {
        isLoading: false,
        headers: [
          { text: 'Cluster', value: 'title' },
          { text: 'Keywords', value: 'keyword' },
          { text: 'SERP Overlap', value: 'score' },
          { text: 'Impressions', value: 'target_impressions' },
          { text: 'Actions', value: 'id' }
        ],
        sortBy: 'target_impressions',
        descending: true
      },

      tableClusterPages: {
        isLoading: false,
        headers: [
          { text: 'URL', value: 'url' },
          { text: 'Matching Cluster', value: 'title' },
          { text: 'Impressions', value: 'target_impressions' }
        ],
        sortBy: 'target_impressions',
        descending: true
      },

      tableCompetitors: {
        isLoading: false,
        headers: [
          { text: 'Top 5 Competitors', value: 'url' },
          { text: '# of Keywords', value: 'keywords' },
          { text: 'Rank', value: 'rank' }
        ]
      },

      tableHoverKeywords: {
        isLoading: false,
        headers: [
          { text: 'Keyword', value: 'keyword' },
          { text: 'Impressions', value: 'target_impressions' }
        ],
        sortBy: 'target_impressions',
        descending: true
      },

      tableInternalLinks: {
        isLoading: false,
        headers: [
          { text: 'URL', value: 'url' },
          { text: 'Anchor text', value: 'anchor_text' }
        ],
        sortBy: 'url',
        descending: true
      },

      articlesNewPriority: null,
      articlesNewFunnel: null,
      articlesNewStatus: null,
      articlesNewDeadline: null,
      articlesNewPageId: null,
      articlesNewCustomUrl: null,
      showChangeTargetUrl: false,
      notesTabs: [
        { text: 'Brief', value: 'brief' },
        { text: 'Research', value: 'research' },
        { text: 'Draft', value: 'draft' },
        { text: 'Final Draft', value: 'finalDraft' }
      ],
      notesTab: 0,

      newUrlRules: value => /^(http|https):\/\/[^ "]+$/.test(value) || 'Invalid URL.',

      chartDataImpressions: null,
      chartDataPotential: null,
      chartOptionsPotential: {
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Rank'
            },
            ticks: {
              min: 1,
              max: 100,
              reverse: true,
              padding: 10
            },
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Impressions'
            },
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false
            },
            ticks: {
              beginAtZero: true,
              padding: 10,
              suggestedMax: 100
            }
          }]
        },
        legend: {
          display: false
        },
        animation: {
          duration: 1000,
          easing: 'easeInOutQuart'
        },
        responsive: true,
        aspectRatio: 1.2,
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return data.labels[tooltipItem[0].index]
            },
            label: function (tooltipItem, data) {
              return data.datasets[0].label[1] + ': ' + data.datasets[0].data[tooltipItem.index].y + ', ' + data.datasets[0].label[0] + ': ' + data.datasets[0].data[tooltipItem.index].x
            }
          }
        }
      },
      chartOptionsImpressions: {
        scales: {
          yAxes: [{
            type: 'linear',
            position: 'left',
            display: false,
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false
            },
            id: 'y-axis-1',
            min: 0,
            ticks: {
              beginAtZero: true,
              suggestedMax: 10
            },
            stacked: false
          }],
          xAxes: [{
            type: 'time',
            display: false,
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false
            },
            time: {
              unit: 'week',
              isoWeekday: true,
              displayFormats: {
                day: 'MMM DD'
              }
            }
          }]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          position: 'nearest'
        },
        legend: {
          display: false
        },
        animation: {
          duration: 1000,
          easing: 'easeInOutQuart'
        },
        aspectRatio: 6
      },
      date: null
    }
  },
  async mounted () {
    await this.loadData()
  },
  watch: {
    async $route () {
      await this.loadData()
    },
    async description () {
      if (!this.savingBrief && this.description !== this.article?.description) {
        this.savingBrief = true
        setTimeout(() => {
          this.saveArticleBrief()
        }, 5000)
      }
    },
    async research () {
      if (!this.savingResearch && this.research !== this.article?.research) {
        this.savingResearch = true
        setTimeout(() => {
          this.saveArticleResearch()
        }, 5000)
      }
    },
    async draft () {
      if (!this.savingDraft && this.draft !== this.article?.draft) {
        this.savingDraft = true
        setTimeout(() => {
          this.saveArticleDraft()
        }, 5000)
      }
    },
    async finalDraft () {
      if (!this.savingFinalDraft && this.finalDraft !== this.article?.final) {
        this.savingFinalDraft = true
        setTimeout(() => {
          this.saveArticleFinalDraft()
        }, 5000)
      }
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'article',
      'keywords',
      'articleUrls',
      'articleExternalUrls',
      'score',
      'pages',
      'pageKeywords',
      'gsc',
      'internalLinks',
      'topics',
      'activeIntegration'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    articleId () {
      return parseInt(this.$route.params.articleId)
    },
    activeTopic () {
      if (!this.article || !this.topics) return 'No hub'
      return this.topics.find(t => t.id === this.article?.topic_id)?.name || 'No hub'
    },
    pageId () {
      return this.article?.page_id
    },
    sharingKey () {
      return this.$route.params.sharingKey
    },
    competingPages () {
      if (!this.article || !this.articleUrls) return

      return this.articleUrls.filter(url => url.url !== this.article.url && url.page_impressions_last > 0) || null
    },
    mergeWithTarget () {
      if (!this.article || !this.pages) return
      const mergeObject = this.pages.find(p => p.page_id === this.article.merge_with)

      return {
        url: mergeObject?.url,
        clusterId: mergeObject?.article_id,
        clusterTitle: mergeObject?.title
      }
    },
    urlMatchMetrics () {
      if (!this.article || !this.pages) return

      const urlMatch = this.pages.filter(page => page.url === this.article.url)
      return urlMatch?.length ? urlMatch : null
    },
    sumOfImpressions () {
      if (!this.article) return 0
      const urlImpressions = this.urlMatchMetrics?.[0].page_impressions_last || 0
      const clusterImpressions = this.article.google_impressions_last || 0
      const clusterMatchImpressions = this.article.target_impressions || 0

      return clusterMatchImpressions + (clusterImpressions - clusterMatchImpressions) + (urlImpressions - clusterMatchImpressions)
    },
    getWidthUrl () {
      if (!this.article || !this.urlMatchMetrics) return { width: '0' }
      return { width: (this.urlMatchMetrics[0].page_impressions_last - this.article.target_impressions) / this.urlMatchMetrics[0].page_impressions_last * 100 + '%' }
    },
    getWidthTargetUrl () {
      if (!this.article || !this.urlMatchMetrics) return { width: '0' }
      return { width: this.article.target_impressions / this.urlMatchMetrics[0].page_impressions_last * 100 + '%' }
    },
    getWidthCluster () {
      if (!this.article || !this.urlMatchMetrics) return { width: '0' }
      return { width: (this.article.google_impressions_last - this.article.target_impressions) / this.article.google_impressions_last * 100 + '%' }
    },
    getWidthTargetCluster () {
      if (!this.article || !this.urlMatchMetrics) return { width: '0' }
      return { width: this.article.target_impressions / this.article.google_impressions_last * 100 + '%' }
    },
    getWidthTarget () {
      if (!this.article || !this.urlMatchMetrics) return { width: '0' }
      return { width: this.article.target_impressions / this.sumOfImpressions * 100 + '%' }
    },
    publishDateFormatted () {
      if (!this.article || !this.article.published_at) return ''
      return new Date(this.article.published_at).toISOString().slice(0, 10)
    },
    deadlineFormatted () {
      if (!this.article || !this.article.deadline) return ''
      return this.article.deadline.slice(0, 10)
    },
    competingPagesWithoutMatch () {
      if (!this.competingPages) return
      return this.competingPages.filter(page => this.getArticleTitle(page.article_id) === '-')
    },
    competingPagesWithMatch () {
      if (!this.competingPages) return
      return this.competingPages.filter(page => this.getArticleTitle(page.article_id) !== '-')
    },
    competingClustersWithoutMatch () {
      if (!this.competingClusters) return
      return this.competingClusters.filter(cluster => !this.getArticleTitle(cluster.article_id) && cluster.article_id !== 'excluded')
    },
    competingClustersWithMatch () {
      if (!this.competingClusters) return
      return this.competingClusters.filter(cluster => cluster.article_id === 'excluded' || this.getArticleTitle(cluster.article_id))
    },
    notesBoxText () {
      if (!this.article) return ''
      if (this.notesTab === 0) return this.description || 'Add notes here...'
      if (this.notesTab === 1) return this.research || 'Add notes here...'
      if (this.notesTab === 2) return this.draft || 'Add notes here...'
      if (this.notesTab === 3) return this.finalDraft || 'Add notes here...'
    },
    activeIntegrationDomain () {
      return this.activeIntegration?.wp_domain
    }
  },
  methods: {
    parseData,
    ...mapActions([
      'loadArticle',
      'loadTopics',
      'loadArticleKeywords',
      'loadArticleUrls',
      'loadArticleExternalUrls',
      'updateArticles',
      'loadScoreAndCommit',
      'loadScoreAndReturn',
      'analyzeLandingPage',
      'loadPagesByArticle',
      'updatePages',
      'loadPageKeywords',
      'updateKeywords',
      'getGSCDataAndReturn',
      'recalculateAlerts',
      'loadInternalLinksByArticle',
      'getPageFromS3',
      'getIntegrations'
    ]),
    async loadData () {
      this.isLoading.article = true
      this.isLoading.chartDataPotential = true
      try {
        await Promise.all([
          this.loadArticleKeywords({
            articleId: this.articleId,
            sharingKey: this.sharingKey
          }),
          this.loadArticleUrls({
            articleId: this.articleId,
            sharingKey: this.sharingKey
          }),
          this.loadArticleExternalUrls({
            articleId: this.articleId,
            sharingKey: this.sharingKey
          }),
          this.loadArticle({
            articleId: this.articleId,
            sharingKey: this.sharingKey
          }),
          this.loadInternalLinksByArticle({
            articleId: this.articleId,
            sharingKey: this.sharingKey
          }),
          this.getIntegrations({ contentplanId: this.contentplanId })
        ])
      } catch (e) {
        console.error(e)
      }
      this.isLoading.article = false

      this.description = this.article?.description
      this.research = this.article?.research
      this.draft = this.article?.draft
      this.finalDraft = this.article?.final
      this.title = this.article?.title || ''
      document.title = this.article?.title + ' | ContentGecko'
      this.getChartDataPotential()
      this.getChartDataCMR()

      if (this.user) {
        await this.loadTopics({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId
        })
      }
      if (this.article.page_id) {
        // if Target URL is not in articleUrls, add it
        if (!this.articleUrls.some(url => url.url === this.article.url)) {
          this.articleUrls.push({
            url: this.article.url,
            article_id: this.article.id,
            article_id_locked: this.article.article_id_locked,
            page_impressions_last: 0,
            page_clicks_last: 0,
            page_position_last: 0,
            page_ctr_last: 0,
            id: 'N/a'
          })
        }

        await this.loadPageKeywords({
          contentplanId: this.contentplanId,
          pageId: this.article.page_id,
          sharingKey: this.sharingKey
        })
        this.groupKeywordsByCluster()
      }

      if (this.article.empty) {
        this.loadArticleUrls({
          articleId: this.articleId,
          sharingKey: this.sharingKey,
          empty: true
        })
      }

      this.getInternalUrls()
      this.getScoreAndCommit()
    },
    async handlePostWordpress () {
    },
    async getPageData () {
      this.isLoading.pageData = true
      try {
        this.pageData = await this.getPageFromS3({
          pageId: this.pageId
        })
        console.log('pageData', this.pageData)
      } catch (e) {
        console.error(e)
      }
      this.isLoading.pageData = false
    },
    async getScoreAndCommit () {
      this.isLoading.score = true

      await this.loadScoreAndCommit({
        contentplanId: this.contentplanId,
        articleId: this.articleId
      })

      this.isLoading.score = false
    },
    async getScoreAndReturn (comparisonArticleId) {
      if (!comparisonArticleId) return
      if (typeof comparisonArticleId !== 'number') return

      const score = await this.loadScoreAndReturn({
        contentplanId: this.contentplanId,
        articleId: [this.articleId, comparisonArticleId]
      })
      return score
    },
    async saveArticleBrief () {
      if (this.description === this.article.description) {
        this.savingBrief = false
        return
      }

      await this.updateArticles({
        contentplanId: this.contentplanId,
        action: 'updateDescription',
        articleIds: [this.articleId],
        description: this.description || null
      })
      this.savingBrief = false
    },
    async saveArticleResearch () {
      if (this.research === this.article.research) {
        this.savingResearch = false
        return
      }

      await this.updateArticles({
        contentplanId: this.contentplanId,
        action: 'updateResearch',
        articleIds: [this.articleId],
        research: this.research || null
      })
      this.savingResearch = false
    },
    async saveArticleDraft () {
      if (this.draft === this.article.draft) {
        this.savingDraft = false
        return
      }

      await this.updateArticles({
        contentplanId: this.contentplanId,
        action: 'updateDraft',
        articleIds: [this.articleId],
        draft: this.draft || null
      })
      this.savingDraft = false
    },
    async saveArticleFinalDraft () {
      if (this.finalDraft === this.article.final) {
        this.savingFinalDraft = false
        return
      }

      await this.updateArticles({
        contentplanId: this.contentplanId,
        action: 'updateFinalDraft',
        articleIds: [this.articleId],
        final: this.finalDraft || null
      })
      this.savingFinalDraft = false
    },
    async updatePageMergeWith (mergeWith) {
      this.isLoading.updatePageMergeWith = true

      try {
        await this.updatePages({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          pageIds: this.article.page_id,
          mergeWith
        })
        await this.loadArticle({
          articleId: this.articleId,
          sharingKey: this.sharingKey
        })
      } finally {
        this.isLoading.updatePageMergeWith = false
      }
    },
    async renameSelectedArticle () {
      this.updateArticles({
        workspaceId: this.workspaceId,
        contentplanId: this.contentplanId,
        action: 'rename',
        articleIds: this.articleId,
        name: this.title || null
      })
    },
    async setPriority (value) {
      if (typeof value === 'undefined') return

      this.isLoading.setPriority = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updatePriority',
          articleIds: this.articleId,
          priority: value
        })
        await this.loadArticle({
          articleId: this.articleId,
          sharingKey: this.sharingKey
        })
      } finally {
        this.isLoading.setPriority = false
      }
    },
    async setFunnel (value) {
      if (!value) return

      this.isLoading.setFunnel = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateFunnel',
          articleIds: this.articleId,
          funnel: value || this.articlesNewFunnel
        })
        await this.loadArticle({
          articleId: this.articleId,
          sharingKey: this.sharingKey
        })
        this.articlesNewFunnel = null
      } finally {
        this.isLoading.setFunnel = false
      }
    },
    async setStatus (value) {
      if (!value) return

      this.isLoading.setStatus = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateStatus',
          articleIds: this.articleId,
          status: value || this.articlesNewStatus
        })
        await this.loadArticle({
          articleId: this.articleId,
          sharingKey: this.sharingKey
        })
        this.articlesNewStatus = null
      } finally {
        this.isLoading.setStatus = false
      }
    },
    async setType (value) {
      if (!value) return

      this.isLoading.setType = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateType',
          articleIds: this.articleId,
          type: value
        })
        await this.loadArticle({
          articleId: this.articleId,
          sharingKey: this.sharingKey
        })
      } finally {
        this.isLoading.setType = false
      }
    },
    async setIntent (value) {
      if (!value) return

      this.isLoading.setIntent = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateIntent',
          articleIds: this.articleId,
          intent: value
        })
        await this.loadArticle({
          articleId: this.articleId,
          sharingKey: this.sharingKey
        })
      } finally {
        this.isLoading.setIntent = false
      }
    },
    async setDeadline (value) {
      if (!value) return
      console.log('setDeadline', value)
      this.isLoading.setDeadline = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateDeadline',
          articleIds: this.articleId,
          deadline: value
        })
        await this.loadArticle({
          articleId: this.articleId,
          sharingKey: this.sharingKey
        })
        this.articlesNewDeadline = null
      } finally {
        this.isLoading.setDeadline = false
      }
    },
    async setShowInMap (value) {
      if (typeof value === 'undefined') return

      this.isLoading.setShowInMap = true
      console.log('setShowInMap', value)

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: value ? 'addToMap' : 'removeFromMap',
          articleIds: this.articleId
        })
        await this.loadArticle({
          articleId: this.articleId,
          sharingKey: this.sharingKey
        })
      } finally {
        this.isLoading.setShowInMap = false
      }
    },
    async updateUrlMatch () {
      if (!this.articlesNewPageId && !this.articlesNewCustomUrl) return

      this.isLoading.targetUrl = true

      try {
        await this.updatePages({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          articleIds: this.articleId,
          pageIds: this.articlesNewPageId,
          customUrl: this.articlesNewCustomUrl || null
        })
        if (this.articlesNewPageId !== 'null') {
          await this.analyzeLandingPage({
            pageId: this.articlesNewPageId
          })
        }
        await this.loadData()
      } finally {
        this.articlesNewPageId = null
        this.isLoading.targetUrl = false
        this.showChangeTargetUrl = false
      }
    },
    async lockArticle () {
      const value = !this.article.article_id_locked

      this.isLoading.lockingArticle = true

      try {
        await this.updatePages({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          pageIds: this.article.page_id,
          lock: value
        })
        await this.loadData({
          articleId: this.articleId,
          sharingKey: this.sharingKey
        })
      } finally {
        this.isLoading.lockingArticle = false
      }
    },
    async getInternalUrls () {
      this.isLoading.selectableTargetUrls = true

      await this.loadPagesByArticle({
        articleId: this.articleId,
        sharingKey: this.sharingKey
      })

      this.selectableTargetUrls = this.pages.filter(u => u.article_id !== this.articleId)

      // add this cluster impressions to pages
      this.selectableTargetUrls = this.selectableTargetUrls.map(iu => {
        const urlWithImpressions = this.articleUrls.filter(u => u.page_id === iu.page_id)
        if (urlWithImpressions.length > 0) {
          return { ...iu, target_impressions: urlWithImpressions[0].target_impressions }
        }
        return { ...iu, target_impressions: 0 }
      })

      this.selectableTargetUrls.sort((a, b) => {
        if (a.target_impressions > b.target_impressions) return -1
        if (a.target_impressions < b.target_impressions) return 1
        if (a.article_id < b.article_id) return -1
        if (a.article_id > b.article_id) return 1
        return 0
      })

      this.isLoading.selectableTargetUrls = false
    },
    async share (action) {
      await this.updateArticles({
        contentplanId: this.contentplanId,
        action: 'share',
        articleIds: [this.articleId],
        share: action
      })

      await this.loadArticle({
        articleId: this.articleId
      })
    },
    suggestedActionText (action) {
      return this.actions.find(a => a.value === action)?.text
    },
    getArticleTitle (articleId) {
      if (!this.pages) return
      return this.pages.find(p => p.article_id === articleId)?.title
    },
    async groupKeywordsByCluster () {
      // this.pageKeywords has rows of keywords and their impressions, but we need to group them by cluster
      const uniqueClusters = new Set(this.pageKeywords.filter(a => a.article_id && (a.article_id !== this.articleId))?.map(a => a.title))
      this.competingClusters = []

      uniqueClusters.forEach(cluster => {
        const clusterKeywords = this.pageKeywords.filter(a => a.title === cluster)
        const clusterImpressions = clusterKeywords.reduce((a, b) => a + b.target_impressions, 0)
        this.competingClusters.push({
          title: cluster,
          target_impressions: clusterImpressions,
          article_id: clusterKeywords[0].article_id
        })
      })

      // add keywords not in content plan to a separate row
      const keywordsNotInContentPlan = this.pageKeywords.filter(keyword => !keyword.article_id && !keyword.excluded && !keyword.in_queue)
      if (keywordsNotInContentPlan.length > 0) {
        const clusterImpressions = keywordsNotInContentPlan.reduce((a, b) => a + b.target_impressions, 0)
        this.competingClusters.push({
          title: 'Inactive keywords',
          target_impressions: clusterImpressions,
          article_id: 'not-in-plan'
        })
      }

      // add keywords in queue to a separate row
      const keywordsInQueue = this.pageKeywords.filter(keyword => keyword.in_queue)
      if (keywordsInQueue.length > 0) {
        const clusterImpressions = keywordsInQueue.reduce((a, b) => a + b.target_impressions, 0)
        this.competingClusters.push({
          title: 'Keywords in queue',
          target_impressions: clusterImpressions,
          article_id: 'in-queue'
        })
      }

      // add excluded keywords to a separate row
      const excludedKeywords = this.pageKeywords.filter(keyword => keyword.excluded)
      if (excludedKeywords.length > 0) {
        const clusterImpressions = excludedKeywords.reduce((a, b) => a + b.target_impressions, 0)
        this.competingClusters.push({
          title: 'Excluded keywords',
          target_impressions: clusterImpressions,
          article_id: 'excluded'
        })
      }

      this.competingClusters = this.competingClusters.sort((a, b) => b.target_impressions - a.target_impressions)

      this.competingClusters = await Promise.all(this.competingClusters.map(async cluster => {
        const score = await this.getScoreAndReturn(cluster.article_id, null)
        return {
          ...cluster,
          score
        }
      }))
    },
    clusterKeywords (articleId) {
      if (!this.pageKeywords) return

      if (articleId === 'not-in-plan') {
        return this.pageKeywords.filter(keyword => !keyword.article_id && !keyword.excluded && !keyword.in_queue)
      }

      if (articleId === 'in-queue') {
        return this.pageKeywords.filter(keyword => keyword.in_queue)
      }

      if (articleId === 'excluded') {
        return this.pageKeywords.filter(keyword => keyword.excluded)
      }

      return this.pageKeywords.filter(keyword => keyword.article_id === articleId)
    },
    async updatePageAction (action) {
      this.isLoading.updatePageAction = true

      try {
        await this.updatePages({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          pageIds: this.article.page_id,
          action
        })
        await Promise.all([
          this.loadPagesByArticle({
            articleId: this.articleId
          }),
          this.loadArticle({
            articleId: this.articleId
          })
        ])
      } finally {
        this.isLoading.updatePageAction = false
      }
    },
    async getChartDataPotential () {
      if (!this.keywords) return
      this.isLoading.chartDataPotential = true

      const dataset = {
        labels: this.keywords.map(keyword => keyword.keyword),
        datasets: [{
          label: ['Rank', 'Impressions'],
          data: this.keywords.map(keyword => ({
            x: Math.min(parseData(keyword.google_position_last) || 0, 100),
            y: keyword.google_impressions_last || 0
          })),
          backgroundColor: '#861086'
        }]
      }

      this.chartDataPotential = dataset
      this.isLoading.chartDataPotential = false
    },
    async getChartDataCMR () {
      this.isLoading.chartDataImpressions = true
      // date is 12 weeks up to now
      this.setDates(12)

      let gscDataArray = [{
        label: 'Cluster Match Impressions',
        backgroundColor: '#b6f1c6',
        borderColor: 'green',
        fill: true,
        data: null,
        yAxisID: 'y-axis-1'
      },
      {
        label: 'Cluster Impressions',
        backgroundColor: '#eae4f7',
        borderColor: '#5e35b1',
        fill: true,
        data: null,
        yAxisID: 'y-axis-1'
      },
      {
        label: 'URL Impressions',
        backgroundColor: '#dde9fd',
        borderColor: '#4285f4',
        fill: true,
        data: null,
        yAxisID: 'y-axis-1'
      }]

      try {
        gscDataArray = await Promise.all(gscDataArray.map(async obj => {
          obj.data = await this.getGSCDataAndReturn({
            contentplanId: this.contentplanId,
            startDate: this.date[0],
            endDate: this.date[1],
            articleIds: obj.label !== 'URL Impressions' ? [this.articleId] : null,
            pages: obj.label !== 'Cluster Impressions' ? [this.article.url] : null
          })
          return obj
        }))

        if (gscDataArray.some(obj => typeof obj === 'string')) {
          console.log('Error', gscDataArray)
          this.isLoading.chartDataImpressions = false
          return
        }

        gscDataArray.forEach(obj => {
          const dateArray = obj?.data.map(x => x.keys[0])
          obj.data = obj?.data.map(x => x.impressions)
          this.calcChartData(dateArray, obj, 'chartDataImpressions')
        })
      } catch (error) {
        console.log(error)
      }

      this.isLoading.chartDataImpressions = false
    },
    async calcChartData (labels, dataset, target) {
      if (!this[target]) {
        this[target] = {
          labels,
          datasets: [dataset]
        }
      } else {
        this[target].datasets.push(dataset)
      }

      this[target].datasets.sort((a, b) => a.data.reduce((a, b) => a + b, 0) - b.data.reduce((a, b) => a + b, 0))
    },
    getLastSunday (date) {
      // Clone the date object so we don't modify the original
      const d = new Date(date)
      // Get the day of the week (begins on Monday)
      const day = d.getDay()
      // Subtract the day of the week from the date to get the last Sunday
      d.setDate(d.getDate() - day)
      // Return the date as a string in the format 'YYYY-MM-DD'
      return d
    },
    setDates (weeks) {
      const today = new Date()
      // take 3 days back (GSC data is 3 days delayed)
      today.setDate(today.getDate() - 3)
      const endDate = this.getLastSunday(today)
      const startDate = new Date()
      startDate.setDate(endDate.getDate() - (weeks * 7) + 1)
      this.date = [startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10)]
    },
    async deleteArticle (articleId) {
      if (articleId !== this.articleId && !confirm('Are you sure you want to delete the competing cluster? This will also exclude all keywords in it.')) return
      this.isLoading.deleteArticles = true

      try {
        this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'delete',
          articleIds: [articleId]
        })
        this.recalculateAlerts({
          contentplanId: this.contentplanId
        })
      } catch (error) {
        console.log(error)
      }

      if (articleId === this.articleId) {
        this.dialogDelete = false
        this.$router.push({
          name: 'Plan',
          params: {
            workspaceId: this.workspaceId,
            contentplanId: this.contentplanId
          }
        })
      } else {
        this.competingClusters = this.competingClusters.filter(x => x.article_id !== articleId)
        this.dialogDeleteCompeting = false
      }

      this.isLoading.deleteArticles = false
    },
    async resetArticle (articleId) {
      if (articleId !== this.articleId && !confirm('Are you sure you want to reset the competing cluster? This will delete the cluster and reset the keywords in it.')) return
      this.isLoading.resetArticles = true

      try {
        this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'reset',
          articleIds: [articleId]
        })
        this.recalculateAlerts({
          contentplanId: this.contentplanId
        })
      } catch (error) {
        console.log(error)
      }

      if (articleId === this.articleId) {
        this.dialogDelete = false
        this.$router.push({
          name: 'Plan',
          params: {
            workspaceId: this.workspaceId,
            contentplanId: this.contentplanId
          }
        })
      } else {
        this.competingClusters = this.competingClusters.filter(x => x.article_id !== articleId)
        this.dialogDeleteCompeting = false
      }

      this.isLoading.resetArticles = false
    },
    async mergeCluster (articleId) {
      if (!confirm('Are you sure you want to merge these clusters? This will move all the keywords from the competing cluster into this one.')) return
      this.isLoading.mergeCluster = articleId

      try {
        await this.updateArticles({
          contentplanId: this.contentplanId,
          articleIdsFrom: [articleId],
          articleIdTo: this.articleId,
          action: 'merge'
        })
        await this.recalculateAlerts({
          contentplanId: this.contentplanId
        })
      } catch (error) {
        console.log(error)
      }

      await this.loadData()
      this.isLoading.mergeCluster = false
    },
    async changeTopic (topicId) {
      this.isLoading.changeTopic = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateTopic',
          articleIds: [this.articleId],
          topicId
        })
        await this.loadArticle({
          articleId: this.articleId,
          sharingKey: this.sharingKey
        })
      } finally {
        this.isLoading.changeTopic = false
      }
    },
    async unexcludeKeywords () {
      this.isLoading.unexcludeKeywords = true
      const keywordsToUnexclude = this.clusterKeywords('excluded').map(keyword => keyword.id)
      console.log('keywordsToUnexclude', keywordsToUnexclude)

      try {
        await this.updateKeywords({
          contentplanId: this.contentplanId,
          keywordIds: keywordsToUnexclude,
          action: 'reset'
        })
        await this.loadPageKeywords({
          contentplanId: this.contentplanId,
          pageId: this.article.page_id,
          sharingKey: this.sharingKey
        })
        this.groupKeywordsByCluster()
      } catch (error) {
        console.log(error)
      }

      this.isLoading.unexcludeKeywords = false
    },
    async handleDialogClick () {
      this.dialogModifyArticle = !this.dialogModifyArticle
      if (this.dialogModifyArticle) this.dialogKey += 1
    }
  },
  components: { ChartComponent, VueShowdown, Dendrogram, ChatGPT }
}
