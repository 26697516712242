import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'WorkspaceSettings',
  data () {
    return {
      isLoading: {
        stripeLink: false
      },

      stripeLink: null
    }
  },
  async mounted () {
    this.loadData()
  },
  watch: {
    async $route () {
      this.loadData()
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'workspaces',
      'activeWorkspace'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    }
  },
  methods: {
    ...mapActions([
      'getStripeLink'
    ]),
    async loadData () {
      this.isLoading.stripeLink = true
      try {
        const res = await this.getStripeLink({
          workspaceId: this.workspaceId
        })
        this.stripeLink = res?.url
      } catch (e) {
        console.error(e)
      }

      this.isLoading.stripeLink = false
    }
  }
}
