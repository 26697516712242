import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'StripeConfirmation',
  data () {
    return {
      attempts: 0,
      maxAttempts: 20,
      error: false
    }
  },
  computed: {
    ...mapGetters([
      'workspaces'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    }
  },
  methods: {
    ...mapActions([
      'loadWorkspaces'
    ]),
    async loadData () {
      await this.loadWorkspaces()
      this.intervalId = setInterval(async () => {
        await this.loadWorkspaces()
        const workspace = this.workspaces.find(workspace => workspace.id === this.workspaceId)
        if (workspace && workspace.subscription_active) {
          clearInterval(this.intervalId)
          this.$router.push({
            name: 'PaidClusterer',
            params: {
              workspaceId: this.workspaceId
            }
          })
        } else if (this.attempts >= this.maxAttempts) {
          clearInterval(this.intervalId)
          this.error = true
        }
        this.attempts++
      }, 5000)
    }
  },
  async mounted () {
    await this.loadData()
  }
}
