import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'GoogleAnalyticsIntegration',
  data () {
    return {
      isLoading: {
        integrations: false,
        save: false
      },
      settings: [
        { label: 'Property (required)', id: 'ga_property', value: '', options: [], disabled: false, required: true },
        { label: 'Events (required)', id: 'ga_events', value: [], options: [], disabled: false, required: true }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'integrations',
      'googleAnalyticsIntegration'
    ]),
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    googleAnalyticsEvents () {
      if (!this.googleAnalyticsIntegration?.ga_events) return []

      return this.googleAnalyticsIntegration.ga_events.split(',')
    }
  },
  methods: {
    ...mapActions([
      'getGoogleAnalyticsProperties',
      'saveGoogleAnalyticsProperty',
      'getIntegrations',
      'getGoogleAnalyticsData'
    ]),
    mapEvents (data = []) {
      return data?.map(item => item.eventName)
    },
    mapGoogleAnalyticsProperties (data = []) {
      return data.map(item => {
        const property = item.property.split('/')?.[1] ?? -1
        return { name: item.displayName, value: Number(property) }
      })
    },
    async handleSave () {
      this.isLoading.save = true
      try {
        await this.saveGoogleAnalyticsProperty({
          contentplanId: this.contentplanId,
          gaProperty: this.settings[0].value,
          gaName: this.settings[0].options.find(item => item.value === this.settings[0].value)?.name,
          ga_events: this.settings[1].value.filter(event => event).join(','),
          type: 'ga-4'
        })
        this.$router.push({
          name: 'ContentplanSettings',
          params: { contentplanId: this.contentplanId }
        })
      } catch (error) {
        console.error(error)
      }
      this.isLoading.save = false
    },
    async loadGoogleAnalyticsProperties () {
      try {
        const { properties } = await this.getGoogleAnalyticsProperties()
        this.settings[0].options = this.mapGoogleAnalyticsProperties(properties)
      } catch (error) {
        console.error(error)
      }
    },
    async loadGoogleAnalyticsEvents () {
      // set date range to be last 6 months
      const startDate = new Date()
      startDate.setMonth(startDate.getMonth() - 6)
      const endDate = new Date()

      try {
        const rows = await this.getGoogleAnalyticsData({
          contentplanId: this.contentplanId,
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
          dimensions: [{ name: 'eventName' }],
          metrics: [{ name: 'eventCount' }]
        })
        this.settings[1].options = this.mapEvents(rows)
      } catch (error) {
        console.error(error)
      }
    },
    fillValues () {
      const integration = this.googleAnalyticsIntegration
      if (!integration) return
      this.settings[0].value = integration.ga_property ?? ''
      this.settings[1].value = integration.ga_events?.split(',')?.filter(event => event) ?? []
    }
  },
  async mounted () {
    this.isLoading.integrations = true
    try {
      await Promise.all([
        this.getIntegrations({ contentplanId: this.contentplanId }),
        this.loadGoogleAnalyticsProperties(),
        this.loadGoogleAnalyticsEvents()
      ])
      this.fillValues()
    } catch (error) {
      console.error(error)
    }
    this.isLoading.integrations = false
  }
}
