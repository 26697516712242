import { mapGetters, mapActions } from 'vuex'

import Sidebar from '@/components/Sidebar/Sidebar.vue'

export default {
  name: 'Pages',
  components: {
    Sidebar
  },
  data () {
    return {
      isLoading: {
        pages: false,
        exclude: false,
        reset: false
      },

      statuses: [
        { text: 'All', value: 'all', count: 'totalCount' },
        { text: 'Active', value: 'active', count: 'activeCount' },
        { text: 'Excluded', value: 'excluded', count: 'excludedCount' }
      ],
      selectedStatus: 'all',
      excluded: null,

      selectedRows: [],
      filterUrl: '',

      tableFooter: {
        itemsPerPage: 100,
        page: 1,
        sortBy: ['google_impressions_last'],
        sortDesc: [true]
      },
      tableHeaders: {
        headers: [
          { text: 'URL', value: 'url' },
          { text: 'Cluster', value: 'title' },
          { text: 'Status', value: 'status_code' },
          { text: 'Impressions', value: 'google_impressions_last' },
          { text: 'Clicks', value: 'google_clicks_last' },
          { text: 'Rank', value: 'google_position_last' },
          { text: 'Internal links', value: 'inLinksCount' },
          { text: 'Published at', value: 'published_at' },
          { value: 'actions' }
        ]
      }
    }
  },
  async mounted () {
    await this.loadData()
  },
  watch: {
    async $route () {
      await this.loadData()
    },
    async tableFooter () {
      if (!this.isLoading.page) {
        await this.loadData()
      }
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'pages'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    showResetAction () {
      if (this.selectedRows?.length < 1) return
      // Show reset action if any of the selected keywords is in a cluster or is excluded
      if (this.selectedRows.some(kw => kw.title || kw.excluded)) return true
    },
    showExcludeAction () {
      if (this.selectedRows?.length < 1) return
      // Show exclude action if any of the selected keywords is not excluded
      if (this.selectedRows.some(kw => !kw.excluded)) return true
    },
    isAnyRowSelected () {
      return this.selectedRows?.length > 0
    }
  },
  methods: {
    ...mapActions([
      'loadPagesByContentplan',
      'updatePages'
    ]),
    itemClassPrepend (item) {
      return item.excluded ? 'excluded' : ''
    },
    async loadData () {
      if (this.isLoading.pages) return
      this.isLoading.pages = true
      document.title = 'Pages | ContentGecko'
      console.log('Loading pages', this.tableFooter.sortBy[0], this.tableFooter.sortDesc[0])

      try {
        await this.loadPagesByContentplan({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          limit: this.tableFooter.itemsPerPage || 100,
          page: this.tableFooter.page || 1,
          search: this.filterUrl || '.*',
          excluded: this.excluded,
          sortColumn: this.tableFooter.sortBy[0] || 'google_impressions_last',
          sortDirection: this.tableFooter.sortDesc[0] ? 'desc' : 'asc'
        })
      } catch (e) {
        console.log(e)
      }

      this.isLoading.pages = false
    },
    isStatusSelected (status) {
      return this.selectedStatuses.includes(status)
    },
    async handleStatusClick (status) {
      if (status === 'all') {
        this.selectedStatus = 'all'
        this.excluded = null
      } else if (status === 'active') {
        this.selectedStatus = 'active'
        this.excluded = 0
      } else if (status === 'excluded') {
        this.selectedStatus = 'excluded'
        this.excluded = 1
      }

      await this.loadData()
    },
    async excludeRow (pageId) {
      if (!this.selectedRows?.length && !pageId) return

      this.isLoading.exclude = true
      const selectedPageIds = this.selectedRows.map(el => el.page_id)

      const pageIds = pageId ? [pageId] : selectedPageIds

      try {
        await this.updatePages({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          pageIds,
          excluded: true
        })
        this.selectedRows = []
      } finally {
        await this.loadData()
        this.isLoading.exclude = false
      }
    },
    async resetRow (pageId) {
      if (!this.selectedRows?.length && !pageId) return

      this.isLoading.reset = true
      const selectedPageIds = this.selectedRows.map(el => el.page_id)

      const pageIds = pageId ? [pageId] : selectedPageIds

      try {
        await this.updatePages({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          pageIds,
          excluded: 0
        })
        this.selectedRows = []
      } finally {
        await this.loadData()
        this.isLoading.reset = false
      }
    },
    formatDate (date) {
      if (!date) return
      return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
    }
  }
}
