import ChartComponent from '@/components/Chart/Chart.vue'
import KPI from '@/components/KPI/KPI.vue'
import parseData from '@/utils/parseData'

export default {
  name: 'RoiCalculator',
  data () {
    return {
      isLoadingChart: false,

      trafficInputs: {
        startTraffic: {
          label: 'Starting Traffic',
          value: 10000,
          unit: 'visits',
          hint: 'The number of visits your website currently gets from organic search'
        },
        targetTraffic: {
          label: 'Target Traffic',
          value: 12000,
          unit: 'visits',
          hint: 'The number of visits your website is going to get from organic search'
        },
        growthTimeframe: {
          label: 'Growth Timeframe (months)',
          value: 6,
          unit: 'months',
          hint: 'The number of months it will take to reach the target traffic'
        }
      },

      costInputs: {
        investment: {
          label: 'SEO cost (€/month)',
          value: 1500,
          unit: 'EUR',
          hint: 'The monthly cost of your SEO efforts'
        },
        period: {
          label: 'SEO period (months)',
          value: 6,
          unit: 'months',
          hint: 'The number of months you will invest in SEO'
        }
      },

      valueInputsCpc: {
        avgCpc: {
          label: 'Avg CPC (€)',
          value: 0.5,
          unit: 'EUR',
          hint: 'The average cost per click of your paid search campaigns'
        }
      },

      valueInputsConvRate: {
        convRate: {
          label: 'Conversion Rate (%)',
          value: 0.5,
          unit: '%',
          hint: 'The conversion rate of your website'
        },
        convValue: {
          label: 'Conversion Value (€)',
          value: 100,
          unit: 'EUR',
          hint: 'The average value of a conversion on your website'
        }
      },

      variables: {
        chartTimeframe: {
          label: 'Calculation Timeframe (months)',
          value: 24,
          unit: 'months',
          hint: 'The number of months you want to calculate the ROI for'
        },
        hangTime: {
          label: 'Hang Time (months)',
          value: 12,
          unit: 'months',
          hint: 'The number of months that the organic traffic will stay at the same level after the SEO efforts have stopped'
        },
        decayRate: {
          label: 'Decay Rate (% MoM)',
          value: 1,
          unit: '%',
          hint: 'The rate at which the organic traffic will decay after the hang time has passed'
        }
      },

      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Value of Additional Traffic',
            backgroundColor: 'rgba(0, 0, 255, 0.3)',
            data: [],
            yAxisID: 'y-axis'
          },
          {
            label: 'SEO Cost',
            backgroundColor: 'rgba(255, 0, 0, 0.3)',
            data: [],
            yAxisID: 'y-axis'
          },
          {
            label: 'Cumulative Profit or Loss',
            borderColor: 'green',
            type: 'line',
            pointRadius: 0,
            fill: false,
            data: [],
            yAxisID: 'y-axis'
          }
        ]
      },
      chartOptions: {
        responsive: true,
        aspectRatio: 1,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis'
          }]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          position: 'nearest',
          callbacks: {
            label: function (tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || ''
              if (label) {
                label += ': '
              }
              label += Math.round(tooltipItem.yLabel)
              return label
            }
          }
        },
        animation: {
          duration: 1000,
          easing: 'easeInOutQuart'
        }
      },

      showAdvanced: false,
      basedOnCpc: false,
      totalAdditionalTraffic: 0
    }
  },
  computed: {
    totalInvestment () {
      return Math.round(this.chartData.datasets[1].data.reduce((a, b) => a + b, 0))
    },
    totalAdditionalTrafficRounded () {
      return Math.round(this.totalAdditionalTraffic)
    },
    totalAdditionalTrafficValue () {
      return Math.round(this.chartData.datasets[0].data.reduce((a, b) => a + b, 0))
    },
    totalProfit () {
      console.log(this.chartData.datasets[2].data.reduce((a, b) => a + b, 0))
      return this.chartData.datasets[2].data.reduce((a, b) => a + b, 0)
    },
    roi () {
      return 1 + (this.totalAdditionalTrafficValue - this.totalInvestment) / this.totalInvestment
    }
  },
  methods: {
    parseData,
    calculateChartData () {
      this.isLoadingChart = true
      // populate chart organic traffic data. 1st month is current traffic, target traffic is reached in seoDuration months. Traffic increases linearly
      // RESET VALUES
      this.chartData.labels = []
      this.chartData.datasets[0].data = []
      this.chartData.datasets[1].data = []
      this.chartData.datasets[2].data = []
      this.totalAdditionalTraffic = 0

      const startTraffic = +this.trafficInputs.startTraffic.value
      const targetTraffic = +this.trafficInputs.targetTraffic.value
      const growthTimeframe = +this.trafficInputs.growthTimeframe.value
      const chartTimeframe = +this.variables.chartTimeframe.value
      const investment = +this.costInputs.investment.value
      const seoDuration = +this.costInputs.period.value
      const trafficValue = this.calcTrafficValue()
      const hangTime = +this.variables.hangTime.value
      const decayRate = +this.variables.decayRate.value

      let currentMonth = 1
      let currentTraffic = startTraffic
      let cumulativeAdditionalTrafficValue = 0
      let cumulativeSpend = 0

      while (currentMonth <= chartTimeframe) {
        this.chartData.labels.push(currentMonth + ' month')

        // Traffic
        if (currentMonth <= growthTimeframe) {
          // If we are in the growth timeframe, calculate traffic based on the growth formula
          currentTraffic = this.calcChange(currentMonth, 1, startTraffic, growthTimeframe, targetTraffic, 'linear')
        } else if (currentMonth <= growthTimeframe + hangTime) {
          // If we are in the hang timeframe, keep traffic at the target traffic level
          currentTraffic = targetTraffic
        } else {
          // If we are in the decay timeframe, calculate traffic based on the decay formula
          currentTraffic = currentTraffic * (1 - (decayRate / 100))
        }

        // Additional Traffic
        const additionalTraffic = this.calcAdditionalTraffic(startTraffic, currentTraffic)
        this.totalAdditionalTraffic += additionalTraffic
        const additionalTrafficValue = this.calcAdditionalTrafficValue(additionalTraffic, trafficValue)
        this.chartData.datasets[0].data.push(additionalTrafficValue)

        // Spend
        if (currentMonth <= seoDuration) {
          // If we are in the SEO duration, calculate spend based on the investment
          cumulativeSpend += investment
          this.chartData.datasets[1].data.push(investment)
        }

        // ROI
        cumulativeAdditionalTrafficValue += additionalTrafficValue
        const profit = cumulativeAdditionalTrafficValue - cumulativeSpend
        this.chartData.datasets[2].data.push(profit)

        currentMonth++
      }

      setTimeout(() => {
        this.isLoadingChart = false
      }, 10)
    },
    calcChange (m, x1, y1, x2, y2, type) {
      // power law formula: y = y1 * (x / x1) ^ (log(y2 / y1) / log(x2 / x1))
      // inputs explained:
      // m = current month number
      // x1 = start month
      // y1 = start traffic
      // x2 = end month
      // y2 = end traffic

      if (type === 'powerLaw') {
        return y1 * Math.pow((m / x1), (Math.log(y2 / y1) / Math.log(x2 / x1)))
      } else if (type === 'logarithmic') {
        return y1 + (y2 - y1) * Math.log(m / x1) / Math.log(x2 / x1)
      } else if (type === 'sigmoidal') {
        return y1 + (y2 - y1) / (1 + Math.pow(Math.E, -m))
      } else if (type === 'linear') {
        return y1 + ((y2 - y1) / (x2 - x1)) * (m - x1)
      } else if (type === 'exponential') {
        return y1 * Math.pow((y2 / y1), (m / x1))
      }
    },
    calcAdditionalTraffic (startTraffic, currentTraffic) {
      return currentTraffic - startTraffic
    },
    calcAdditionalTrafficValue (additionalTraffic, trafficValue) {
      return additionalTraffic * trafficValue
    },
    calcCumulativeSpend (month, investment) {
      return month * investment
    },
    calcTrafficValue () {
      if (this.basedOnCpc) {
        return this.valueInputsCpc.avgCpc.value
      } else {
        return (+this.valueInputsConvRate.convRate.value / 100) * this.valueInputsConvRate.convValue.value
      }
    }
  },
  components: { ChartComponent, KPI },
  mounted () {
    this.calculateChartData()
  }
}
