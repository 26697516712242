const setAuthToken = (state, token) => {
  state.token = token

  if (token) {
    window.localStorage.setItem('jwt', token)
  } else {
    window.localStorage.removeItem('jwt')
  }
}

const setSidebar = (state, show) => {
  state.showSidebar = show
}

const updateWorkspaces = (state, workspaces) => {
  state.workspaces = workspaces
}

const updateActiveWorkspace = (state, workspace) => {
  state.activeWorkspace = workspace
}

const updateContentplans = (state, contentplans) => {
  state.contentplans = contentplans
}

const updateActiveContentplan = (state, contentplan) => {
  state.activeContentplan = contentplan
}

const updateContentplanKeywords = (state, keywords) => {
  state.keywords = keywords
}

const updatePageKeywords = (state, keywords) => {
  state.pageKeywords = keywords
}

const updateRelatedKeywords = (state, keywords) => {
  state.keywords.related = keywords
}

const updateArticleKeywords = (state, keywords) => {
  state.keywords = keywords
}

const addArticleRankings = (state, rankings) => {
  const newArticleKeywords = []

  for (let i = 0; i < state.keywords.length; i++) {
    newArticleKeywords.push({
      ...state.keywords[i],
      ...(rankings.find((itmInner) => itmInner.id === state.keywords[i].id) || { rank: 0 })
    })
  }

  newArticleKeywords.sort((a, b) => {
    if (a.rank === 0) return 1000
    else if (b.rank === 0) return -1000
    else {
      return a.rank - b.rank
    }
  })

  state.keywords = newArticleKeywords
}

const updateArticleUrls = (state, urls) => {
  state.articleUrls = urls
}

const updateExternalUrls = (state, urls) => {
  state.articleExternalUrls = urls
}

const updateScore = (state, score) => {
  state.score = score
}

const clearCrawl = (state) => {
  state.crawl = null
}

const loadCrawl = (state, crawl) => {
  if (state.crawl?.keywords_to_crawl) {
    crawl.keywords_crawled = state.crawl.keywords_to_crawl - crawl.keywords_to_crawl
    crawl.keywords_to_crawl = state.crawl.keywords_to_crawl
    state.crawl = crawl
    return
  }
  state.crawl = crawl
}

const loadTopics = (state, topics) => {
  state.topics = topics
}

const loadArticles = (state, articles) => {
  state.articles = articles
}

const loadContentplanInternalURLs = (state, urls) => {
  state.internalUrls = urls
}

const loadArticle = (state, article) => {
  state.article = article
}

const loadTopicArticles = (state, response) => {
  state.topic.articles = response.articles
  state.topic.articlesRemaining = response.articlesRemaining
}

const loadMoreTopicArticles = (state, response) => {
  state.topic.articles.push(...response.articles)
  state.topic.articlesRemaining = response.articlesRemaining
}

const updateGSCDataByDate = (state, gscData) => {
  state.gsc.byDate = gscData
}

const updateGSCDataByQuery = (state, gscData) => {
  state.gsc.byQuery = gscData
}

const updateGSCDataByPage = (state, gscData) => {
  state.gsc.byPage = gscData
}

const updatePages = (state, pages) => {
  state.pages = pages
}

const loadPageArticles = (state, articles) => {
  state.pageArticles = articles
}

// INTERNAL LINKS
const updateInternalLinks = (state, links) => {
  state.internalLinks = links
}

// CONTENTPLAN
const renameContentplan = (state, { contentplanId, name }) => {
  state.contentplans = state.contentplans.map(c => {
    if (c.id === contentplanId) {
      c.name = name
    }
    return c
  })
}

// TOPIC
const topicNew = (state, { topicId, name, contentplanId }) => {
  state.topics.push({
    id: topicId,
    name,
    contentplan_id: contentplanId
  })
}

const topicRename = (state, { topicId, name }) => {
  state.topics = state.topics.map(t => {
    if (t.id === topicId) {
      t.name = name
    }
    return t
  })
}

const topicExclude = (state, { topicIds }) => {
  state.topics = state.topics.filter(t => !topicIds.includes(t.id))
}

// PAGES
const pageExclude = (state, { pageIds }) => {
  state.pages = state.pages.filter(p => !pageIds.includes(p.page_id))
}

const pageMatchLock = (state, { pageIds }) => {
  state.pages = state.pages.map(p => {
    if (pageIds.includes(p.page_id)) {
      p.article_id_locked = !p.article_id_locked
    }
    return p
  })
}

const pageMatchUpdate = (state, { pageId, articleId }) => {
  state.pages = state.pages.map(p => {
    if (p.page_id === pageId) {
      p.article_id = articleId
      p.article_id_locked = true
    }
    return p
  })
}

// ARTICLES
const loadArticlesAdvanced = (state, articles) => {
  state.articles = articles.articles
  state.keywords = articles.keywords
  state.articleCounts = articles.counts
}

const articleExclude = (state, { articleIds }) => {
  state.articles = state.articles.filter(a => !articleIds.includes(a.id))
}

const articleRename = (state, { articleId, title }) => {
  state.articles = state.articles.map(a => {
    if (a.id === articleId) {
      a.title = title
    }
    return a
  })
}

const articleMove = (state, { articleIds, topicId }) => {
  state.articles = state.articles.map(a => {
    if (articleIds.includes(a.id)) {
      a.topic_id = topicId
    }
    return a
  })
}

const articleChangeTag = (state, { articleIds, tag, newValue }) => {
  state.articles = state.articles.map(a => {
    if (articleIds.includes(a.id)) {
      a[tag] = newValue
    }
    return a
  })
}

const articleNew = (state, { articleId, title, topicId, contentplanId }) => {
  state.articles.push({
    id: articleId,
    title,
    topic_id: topicId,
    contentplan_id: contentplanId
  })
}

const articleUpdateMainKeyword = (state, { articleId, keywordId, keyword }) => {
  // console.log('articleUpdateMainKeyword', articleId, keywordId)
  state.articles = state.articles.map(a => {
    if (a.id === articleId) {
      a.main_keyword_id = keywordId
      a.title = keyword
    }
    return a
  })
}

// KEYWORDS
const keywordExclude = (state, { keywordIds }) => {
  state.keywords = state.keywords.map(k => {
    if (keywordIds.includes(k.id)) {
      k.excluded = true
      k.article_id = null
    }
    return k
  })
}

const keywordMoveCluster = (state, { keywordIds, articleId }) => {
  state.keywords = state.keywords.map(k => {
    if (keywordIds.includes(k.id)) {
      k.article_id = articleId
    }
    return k
  })
}

const integrations = (state, data) => {
  state.integrations = data
}

const activeIntegration = (state, data) => {
  state.activeIntegration = data
}

const setSnackbar = (state, data) => {
  state.snackbar = { ...data }
}

export default {
  setAuthToken,
  setSidebar,
  updateWorkspaces,
  updateActiveWorkspace,
  updateContentplans,
  updateActiveContentplan,
  updateContentplanKeywords,
  updatePageKeywords,
  updateRelatedKeywords,
  updateArticleKeywords,
  addArticleRankings,
  updateArticleUrls,
  updateExternalUrls,
  updateScore,
  clearCrawl,
  loadCrawl,
  loadTopics,
  loadArticles,
  loadContentplanInternalURLs,
  loadArticle,
  loadTopicArticles,
  loadMoreTopicArticles,
  updateGSCDataByDate,
  updateGSCDataByQuery,
  updateGSCDataByPage,
  updatePages,
  loadPageArticles,
  // INTERNAL LINKS
  updateInternalLinks,
  // CONTENTPLAN
  renameContentplan,
  // TOPIC
  topicNew,
  topicRename,
  topicExclude,
  // PAGES
  pageExclude,
  pageMatchLock,
  pageMatchUpdate,
  // ARTICLES
  loadArticlesAdvanced,
  articleExclude,
  articleRename,
  articleMove,
  articleChangeTag,
  articleNew,
  articleUpdateMainKeyword,
  // KEYWORDS
  keywordExclude,
  keywordMoveCluster,
  // INTEGRATIONS
  integrations,
  activeIntegration,
  setSnackbar
}
